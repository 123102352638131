<script setup lang="ts">
import {NButton} from 'naive-ui'
import {type FieldTypeValue} from '@level3/components/@shared/FieldType/useFieldTypes'

const search = ref('')
const selectedFieldType = ref<FieldTypeValue>('all')
const columnData = ref([])
</script>

<template>
  <DataAssetSubHeader
    :step-number="2"
    title="Set up and run preprocess"
    description="Configure settings for asset columns and the preprocess task."
  >
    <template #descriptionExtra>
      <NButton
        tag="a"
        text
        class="text-primary !hover:underline"
        type="primary"
        size="tiny"
      >
        <template #icon>
          <div class="i-solar-square-top-down-line-duotone" />
        </template>
        <span>Raw Data</span>
      </NButton>
    </template>
    <template #actions>
      <div class="flex items-center gap-2">
        <NButton type="tertiary" quaternary>
          <template #icon>
            <div class="i-solar-diskette-line-duotone" />
          </template>
          Saved
        </NButton>
        <NButton type="primary">
          <template #icon>
            <div class="i-solar-play-circle-line-duotone" />
          </template>
          Run Preprocess
        </NButton>
      </div>
    </template>
  </DataAssetSubHeader>
  <section class="container space-y-4">
    <div class="errors-section">
      <NAlert title="We found 3 errors on this page" type="error" closable>
        <div class="flex items-center gap-2">
          <p>Click 'Review Errors' to check them out and make any updates.</p>
        </div>
      </NAlert>
    </div>
    <div class="space-y-2">
      <span class="font-medium text-foreground">Settings</span>
      <DataAssetPreprocessSettings />
    </div>
    <div class="space-y-2">
      <span class="font-medium text-foreground">Asset columns</span>
      <DataAssetPreprocessAssetDataTable
        v-model="search"
        v-model:selectedFieldType="selectedFieldType"
        :data="columnData"
      />
    </div>
  </section>
</template>
