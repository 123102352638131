<script setup lang="ts">
import AssetsList from '@level3/features/dataAssets/components/AssetsList/AssetsList.vue'
import {usePath} from '@level3/features/navigation/composables/usePath'
import {type AssetPreview} from '@level3/features/dataAssets/types/asset'
import {NButton} from 'naive-ui'

const {clientSlug, pipelineSlug} = usePath()
const assetsData = ref<AssetPreview[]>([])
</script>

<template>
  <div class="p-12">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-2xl font-bold">Data Assets</h1>
      <NButton type="primary">
        <template #icon>
          <div class="i-solar-add-circle-linear" />
        </template>
        Create Data Asset
      </NButton>
    </div>
    <AssetsList
      :client-slug="clientSlug"
      :pipeline-slug="pipelineSlug"
      :assets-data="assetsData"
    />
  </div>
</template>
