<script lang="ts" setup>
interface Props {
  title?: string
  subtitle?: string
  closable?: boolean
}

withDefaults(defineProps<Props>(), { closable: false, })
const emit = defineEmits(['dismiss'])

function dismiss() {
  emit('dismiss', true)
}
</script>

<template>
  <div class="rounded-md bg-amber-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="i-solar-danger-triangle-bold text-amber-500" />
      </div>
      <div class="ml-3">
        <h3 v-if="title" class="font-medium">
          {{ title }}
        </h3>
        <div v-if="subtitle" class="text-gray-500 text-sm">
          <p>{{ subtitle }}</p>
        </div>
        <div v-if="title || subtitle" class="mt-2" />
        <slot />
      </div>
      <div v-if="closable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex bg-amber-50 rounded-md p-1.5 text-amber-500 hover:bg-amber-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-violet-50 focus:ring-amber-500"
            @click="dismiss"
          >
            <span class="sr-only">Dismiss</span>
            <span class="i-solar-close-circle-outline text-gray-800" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
