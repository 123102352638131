<script setup lang="ts">
import { defineProps } from 'vue'
import EnvironmentTopBar from '@app/components/EnvironmentTopBar.vue'
import MetadataChangeConfirmModal from '@shared/components/basic/modal/MetadataChangeConfirmModal.vue'
import { DialogsWrapper } from 'vuejs-confirm-dialog'
import GlobalProvider from '@shared/components/providers/GlobalProvider'
import PipelineConflictConfirmModal from '@/pipelines/components/PipelineConflictConfirmModal.vue'

interface Props {
  hasLoadedClientData: boolean
  showPipelineConflictModal: boolean
  isModalOpened: boolean
}

const props = defineProps<Props>()

const {
  hasLoadedClientData,
  showPipelineConflictModal,
  isModalOpened,
} = toRefs(props)
</script>

<template>
  <NModalProvider>
    <NDialogProvider>
      <EnvironmentTopBar />
      <RouterView v-if="hasLoadedClientData" />
    </NDialogProvider>
  </NModalProvider>

  <!-- Modals -->
  <PipelineConflictConfirmModal :showModal="showPipelineConflictModal" />
  <MetadataChangeConfirmModal :showModal="isModalOpened" />
  <DialogsWrapper />
  <!-- Modals -->

  <!-- Notifications -->
  <NMessageProvider placement="bottom-left" :duration="10000" closable keepAliveOnHover>
    <NNotificationProvider>
      <GlobalProvider style="height: 0; width: 0; visibility: hidden" />
    </NNotificationProvider>
  </NMessageProvider>
  <!-- Notifications -->
</template>
