<script lang="ts">
const pageSize = 10
</script>
<script lang="ts" setup>
import {type Client} from '@level3/features/clients/types/client'
import {useFuse} from '@vueuse/integrations/useFuse'



type Props = {
  clients: Client[]
  userClients: Client[]
  isLoading: boolean
  showActionsTrigger?: 'hover' | 'always'
}

withDefaults(defineProps<Props>(), { showActionsTrigger: 'hover', })

const search = defineModel<string>('search', {default: ''})

const emit = defineEmits<{
  addClient: [Client]
  removeClient: [Client]
}>()

const {results: filteredClients} = useFuse(search, () => __props.clients, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['displayName', 'config', 'id', 'name'],
  },
})

const page = ref(1)
whenever(search, () => {
  page.value = 1
})

const pageCount = computed(() =>
  Math.ceil(filteredClients.value.length / pageSize),
)

const clientsPage = computed(() =>
  filteredClients.value
    .slice((page.value - 1) * pageSize, page.value * pageSize)
    .map((client) => client.item),
)
</script>

<template>
  <div class="space-y-2">
    <NInput v-model:value="search" placeholder="Search" autofocus>
      <template #prefix>
        <div class="i-solar-magnifer-linear mr-2 text-xs text-neutral-400" />
      </template>
    </NInput>
    <div class="min-h-[32rem] py-2">
      <NEmpty
        v-if="search && clientsPage.length === 0"
        :description="`No clients found for ${search}`"
      >
        <template #extra>
          <NButton secondary type="primary" @click="search = ''"
            >Clear search</NButton
          >
        </template>
      </NEmpty>
      <ClientOption
        v-for="client in clientsPage"
        v-else
        :key="client.name"
        :client="client"
        :user-clients="userClients"
        :show-actions-trigger="showActionsTrigger"
        @add-client="emit('addClient', client)"
        @remove-client="emit('removeClient', client)"
      />
    </div>
    <div class="flex justify-center">
      <NPagination v-model:page="page" :page-count="pageCount" />
    </div>
  </div>
</template>
