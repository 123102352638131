<script setup lang="ts">
import {NInput, NSwitch, type DataTableColumns, NTooltip} from 'naive-ui'
import LabelWithTooltip from '@level3/components/@shared/LabelWithTooltip.vue'
import PrimitiveFieldTypeSelect from '@level3/components/@shared/FieldType/PrimitiveFieldTypeSelect.vue'
import {type FieldTypeValue} from '@level3/components/@shared/FieldType/useFieldTypes'
import {useFuse} from '@vueuse/integrations/useFuse'
import {useStorage} from '@vueuse/core'
import FieldTypeIcon from '@level3/components/@shared/FieldType/FieldTypeIcon.vue'
import FriendlyValuesList from '@level3/components/@shared/FriendlyValuesList.vue'

type ColumnData = {
  name: string
  description: string
  gbqColumnName: string
  fieldType: FieldTypeValue | ''
  sampleValues: string[]
  isDropped?: boolean
}

type Props = {
  data: ColumnData[]
}

withDefaults(defineProps<Props>(), { data: () => ([]), })
const search = defineModel<string>('search', {default: ''})
const selectedFieldType = defineModel<FieldTypeValue>('selectedFieldType', {
  default: 'all',
  required: true,
})

const {results: searchResults} = useFuse(search, __props.data, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['name', 'description', 'gbqColumnName', 'sampleValues', 'fieldType'],
    threshold: 0.2,
    includeMatches: true,
  },
})

type FuseResultMatch = (typeof searchResults.value)[number]['matches']

type ColumnDataWithMatches = ColumnData & {
  matches?: FuseResultMatch
}

const filteredData = computed(() => {
  if (searchResults.value.length === 0) {
    const baseData = search.value ? [] : __props.data
    return sortByPinnedColumns(baseData)
  }

  const filtered = searchResults.value.map<ColumnDataWithMatches>((result) => ({
    ...result.item,
    matches: result.matches,
  }))

  if (selectedFieldType.value === 'all') {
    return sortByPinnedColumns(filtered)
  }

  return sortByPinnedColumns(
    filtered.filter((item) => item.fieldType === selectedFieldType.value),
  )
})

function sortByPinnedColumns(items: ColumnDataWithMatches[]) {
  return [...items].sort((a, b) => {
    const aIsPinned = pinnedColumns.value.includes(a.name)
    const bIsPinned = pinnedColumns.value.includes(b.name)

    if (aIsPinned && !bIsPinned) return -1
    if (!aIsPinned && bIsPinned) return 1
    return 0
  })
}

const focusedGbqRowIndex = ref<number>()

function highlightText(text: string, matches?: FuseResultMatch) {
  if (!matches?.length) return text

  const match = matches.find((m) => m.value === text)
  if (!match?.indices.length) return text

  let result = ''
  let lastIndex = 0

  for (const [start, end] of match.indices) {
    result += text.slice(lastIndex, start)
    result += `<span class="bg-warning/30 text-warning-foreground">${text.slice(start, end + 1)}</span>`
    lastIndex = end + 1
  }

  result += text.slice(lastIndex)
  return result
}

const pinnedColumns = useStorage<string[]>(
  'of/data-asset-preprocess-pinned-columns',
  [],
)

function toggleColumnPin(columnName: string) {
  const isPinned = pinnedColumns.value.includes(columnName)
  pinnedColumns.value = isPinned
    ? pinnedColumns.value.filter((name) => name !== columnName)
    : [...pinnedColumns.value, columnName]
}

function createColumns(): DataTableColumns<ColumnDataWithMatches> {
  return [
    {
      type: 'selection',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'raw column name',
      key: 'name',
      fixed: 'left',
      width: 200,
      render(row) {
        const isPinned = pinnedColumns.value.includes(row.name)
        return h('div', {class: 'flex flex-col group'}, [
          h('div', {class: 'flex items-center gap-1'}, [
            h('div', {
              class: 'font-medium',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              innerHTML: highlightText(row.name, row.matches),
            }),
            h(
              NTooltip,
              {},
              {
                trigger: () =>
                  h('div', {
                    class: `i-solar-pin-bold cursor-pointer h-3 ${isPinned ? 'text-primary' : 'text-muted-foreground/20'} ${isPinned ? '' : 'opacity-0 group-hover:opacity-100'}`,
                    onClick() {
                      toggleColumnPin(row.name)
                    },
                  }),
                default: () => (isPinned ? 'Unpin' : 'Pin'),
              },
            ),
          ]),
          h('div', {
            class: 'text-sm text-foreground',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            innerHTML: highlightText(row.description, row.matches),
          }),
        ])
      },
    },
    {
      title: 'GBQ Column Name',
      key: 'gbqColumnName',
      render(row, rowIndex) {
        return h(NInput, {
          value: row.gbqColumnName,
          onUpdateValue(value: string) {
            row.gbqColumnName = value
          },
          maxlength: 15,
          showCount: focusedGbqRowIndex.value === rowIndex,
          onFocus() {
            focusedGbqRowIndex.value = rowIndex
          },
          onBlur() {
            focusedGbqRowIndex.value = undefined
          },
        })
      },
    },
    {
      title: 'drop column',
      key: 'isDropped',
      width: 120,
      render(row) {
        return h(NSwitch, {
          value: row.isDropped,
          size: 'small',
          onUpdateValue(value: boolean) {
            row.isDropped = value
          },
        })
      },
    },
    {
      title: 'field type',
      key: 'fieldType',
      render(row) {
        return h(PrimitiveFieldTypeSelect, {
          modelValue: row.fieldType || undefined,
          placeholder: 'Select field type',
          omit: ['all'],
          onUpdateModelValue(value: FieldTypeValue) {
            row.fieldType = value
          },
        })
      },
    },
    {
      title: () =>
        h(LabelWithTooltip, {
          labelClass: 'text-muted-foreground',
          label: 'sample values',
          tooltip:
            'Sample values are stored and may be stale if data was updated but not refreshed',
        }),
      key: 'sampleValues',
      width: 300,
      ellipsis: {
        tooltip: true,
      },
      render(row) {
        return h(FriendlyValuesList, {
          values: row.sampleValues,
          max: 3,
        })
      },
    },
  ]
}

const tableColumns = createColumns()
const checkedRowKeys = ref<string[]>([])
const showBulkEditModal = ref(false)

const selectedRows = computed(() => {
  return filteredData.value.filter((row) =>
    checkedRowKeys.value.includes(row.name),
  )
})

function handleBulkEditSave() {
  // Handle any post-save logic here if needed
  checkedRowKeys.value = []
}
</script>

<template>
  <div class="@container space-y-4">
    <div class="flex items-center justify-between gap-4 @lg:flex-row">
      <div class="left-side-wrapper flex items-center gap-4">
        <NInput v-model:value="search" class="min-w-72" placeholder="Search...">
          <template #suffix>
            <i class="i-solar-magnifer-outline text-muted-foreground" />
          </template>
        </NInput>
        <div
          class="filters-wrapper flex items-center gap-2 text-muted-foreground"
        >
          <i class="i-solar-tuning-2-outline" />
          <span class="text-muted-foreground">Filter:</span>
          <PrimitiveFieldTypeSelect v-model="selectedFieldType" ghost />
        </div>
      </div>

      <div class="right-side-wrapper flex items-center gap-4">
        <div class="flex items-center gap-4">
          <NTooltip :disabled="checkedRowKeys.length > 0">
            <template #trigger>
              <NButton quaternary @click="showBulkEditModal = true">
                <template #icon>
                  <i class="i-solar-layers-outline" />
                </template>
                <template v-if="checkedRowKeys.length > 0">
                  Bulk edit ({{ checkedRowKeys.length }} items)
                </template>
              </NButton>
            </template>
            Select columns to edit multiple items at once.
          </NTooltip>
          <NButton>
            <template #icon>
              <i class="i-solar-refresh-outline" />
            </template>
            Refresh & Review
          </NButton>
        </div>
      </div>
    </div>
    <NDataTable
      v-model:checked-row-keys="checkedRowKeys"
      :columns="tableColumns"
      :data="filteredData"
      :row-key="(row) => row.name"
      :scroll-x="1000"
      size="small"
    >
      <template #empty>
        <div
          v-if="search || selectedFieldType !== 'all'"
          class="py-8 text-center"
        >
          <i
            class="i-solar-document-search-broken text-3xl text-muted-foreground"
          />
          <div class="mt-2 text-base text-foreground">
            No matching columns found
          </div>
          <div
            class="mt-1 flex items-center justify-center gap-1 text-sm text-muted-foreground"
          >
            No columns
            <template v-if="search">
              matching "{{ search }}"
              <template v-if="selectedFieldType !== 'all'"> with type</template>
            </template>
            <template v-if="selectedFieldType !== 'all'">
              <template v-if="!search">with type</template>
              <span class="inline-flex items-center gap-1">
                <FieldTypeIcon :type="selectedFieldType" class="text-lg" />
                {{ selectedFieldType }}
              </span>
            </template>
          </div>
          <div class="mt-3 text-sm text-muted-foreground">
            Try adjusting your search or filters
          </div>
        </div>
      </template>
    </NDataTable>
    <DataAssetPreprocessBulkEditModal
      v-model:show="showBulkEditModal"
      :columns="tableColumns"
      :selected-rows="selectedRows"
      @save="handleBulkEditSave"
    />
  </div>
</template>
