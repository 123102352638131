<script setup lang="ts">
import {NText, type SelectRenderLabel, type SelectRenderTag} from 'naive-ui'

type DataUpdateType = 'incremental' | 'snapshot'

type DataUpdateTypeOption = {
  label: string
  value: DataUpdateType
  icon: string
  description: string
}

const dataUpdateType = defineModel<DataUpdateType>()

const renderSingleSelectTag: SelectRenderTag = ({option}) => {
  const opt = option as DataUpdateTypeOption
  return h('div', null, opt.label)
}

const renderLabel: SelectRenderLabel = (option) => {
  const opt = option as DataUpdateTypeOption
  return h(
    'div',
    {
      class: 'flex items-center p-1',
    },
    [
      h('div', {
        class: [opt.icon, 'text-xl text-primary-foreground'],
      }),
      h(
        'div',
        {
          class: 'ml-3 py-1',
        },
        [
          h('div', {class: 'font-medium'}, opt.label),
          h(
            NText,
            {
              depth: 3,
              tag: 'div',
              class: 'max-w-40 text-xs text-wrap leading-tight',
            },
            {default: () => opt.description},
          ),
        ],
      ),
    ],
  )
}

const options: DataUpdateTypeOption[] = [
  {
    label: 'Incremental',
    value: 'incremental',
    icon: 'i-solar-circle-top-up-line-duotone',
    description:
      'Only updates or new records from the previous day are included.',
  },
  {
    label: 'Snapshot',
    value: 'snapshot',
    icon: 'i-solar-snowflake-line-duotone',
    description:
      'The file contains all records from the underlying table, refreshed daily.',
  },
]
</script>

<template>
  <n-select
    v-model:value="dataUpdateType"
    :options="options"
    :render-label="renderLabel"
    :render-tag="renderSingleSelectTag"
    :consistent-menu-width="false"
  />
</template>
