import http from '@app/utils/http-client'
import {type Client} from '@level3/types/client'
import {type StrapiDataArray} from '@level3/types/strapi'

export const getClients: () => Promise<Client[]> = async () => {
  const response = await http.get<StrapiDataArray<Client>>('/clients?sort=name')
  const clients: Client[] = response.data.data.map((clientResponse) => ({
    ...clientResponse.attributes,
    id: clientResponse.id,
  }))
  return clients
}

export const createClient: ({
  name,
  slug,
  type,
}: {
  name: string
  slug: string
  type: string
}) => Promise<Client> = async ({name, slug, type}) => {
  try {
    const response = await http.post<Client>('/clients-v2', {
      data: {
        displayName: name,
        name: slug,
        config: {},
        tags: [
          {
            value: type,
          },
        ],
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error(error instanceof Error ? error.message : String(error))
  }
}
