import {getUser, updateUser} from '@level3/features/users/services/user'
import {type Client} from '@level3/features/clients/types/client'
import {type User} from '@level3/features/users/types/user'
import Global from '@shared/utils/global'
import {useQuery, useQueryClient} from '@tanstack/vue-query'
import {watch} from 'vue'

export function useUser() {
  const queryClient = useQueryClient()

  const {
    data: user,
    isLoading: isLoadingUser,
    error,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
    staleTime: 10_000,
    refetchOnMount: true,
  })

  watch(error, (error) => {
    if (error) {
      Global.error(error.message)
    }
  })

  const handleUpdateUser = async (user: Partial<User>) => {
    try {
      const updatedUser = await updateUser(user)
      queryClient.setQueryData(['user'], updatedUser)
    } catch (error: unknown) {
      console.error(error)
      if (error instanceof Error) {
        Global.error(error.message)
      } else {
        Global.error('An error occurred while updating the user')
      }
    }
  }

  const onAddUserClient = async (client: Client) => {
    if (!user.value) {
      Global.error('No user identified.')
      return
    }

    const updatedUser = {
      ...user.value,
      clients: [...(user.value?.clients ?? []), client],
    }

    await handleUpdateUser(updatedUser)
  }

  const onRemoveUserClient = async (client: Client) => {
    if (!user?.value?.clients) {
      Global.error('No user identified.')
      return
    }

    const updatedUser = {
      ...user.value,
      clients: user.value.clients.filter((c) => c.id !== client.id),
    }

    await handleUpdateUser(updatedUser)
  }

  return {
    user,
    isLoadingUser,
    onAddUserClient,
    onRemoveUserClient,
  }
}
