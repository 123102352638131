<script setup lang="ts">
import type {TooltipProps} from 'naive-ui'

type Props = {
  label?: string
  tooltip?: string
  isUnderlined?: boolean
  size?: 'sm' | 'md' | 'lg'
  nTooltipProps?: TooltipProps
  labelClass?: string
}
withDefaults(defineProps<Props>(), { isUnderlined: true,size: 'sm', })
const slots = defineSlots<{
  label(): unknown
  tooltip(): unknown
}>()
const sizeClass = computed(() => {
  return {
    sm: 'max-w-xs',
    md: 'max-w-sm',
    lg: 'max-w-md',
  }[__props.size]
})

const hasTooltipContent = computed(() => {
  return Boolean(slots.tooltip || __props.tooltip)
})

const isShowingTooltip = ref(false)
</script>

<template>
  <NTooltip
    v-bind="nTooltipProps"
    :disabled="!hasTooltipContent"
    :class="sizeClass"
    :delay="400"
    :show-arrow="false"
    @update:show="isShowingTooltip = $event"
  >
    <template #trigger>
      <slot name="label">
        <span
          :class="[
            labelClass,
            {
              'underline underline-offset-3 underline-dotted underline-foreground/30 hover:text-foreground':
                hasTooltipContent && isUnderlined,
            },
          ]"
          class="cursor-default text-foreground"
        >
          {{ label }}
        </span>
      </slot>
    </template>
    <slot name="tooltip">
      {{ tooltip }}
    </slot>
  </NTooltip>
</template>
