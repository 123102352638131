<script setup lang="ts">
type Props = {
  stepNumber?: number
  title?: string
  description?: string
  descriptionExtra?: string
}

const props = defineProps<Props>()

const slots = defineSlots<{
  actions?: () => VNode
  descriptionExtra?: () => VNode
}>()
</script>

<template>
  <div class="flex items-center justify-between bg-background p-4">
    <div class="flex-1 space-y-1">
      <div class="flex items-center gap-2">
        <span
          v-if="stepNumber"
          class="text-lg font-medium text-muted-foreground"
        >
          Step {{ stepNumber }}:
        </span>
        <span class="text-lg font-medium text-foreground" role="heading">{{
          title
        }}</span>
      </div>
      <p v-if="description" class="text-sm text-foreground">
        {{ description }}
      </p>
      <slot
        v-if="slots.descriptionExtra || props.descriptionExtra"
        name="descriptionExtra"
      >
        <p class="text-sm text-muted-foreground">{{ descriptionExtra }}</p>
      </slot>
    </div>
    <!-- Slot for custom action buttons -->
    <slot name="actions">
      <span class="text-sm text-muted-foreground" embedded>
        -- Space for actions slot --
      </span>
    </slot>
  </div>
</template>
