/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Why are these colors in HSL?
 *
 * - HSL is easier to reason about for color contrast and logical color schemes than HEX
 * - Unocss has a opacity variable that is easier to use with HSL than HEX
 * example:
 *
 * ```html
 * <div class="bg-background/50">
 * ```
 */
export const primitiveColors = {
  white: '0 0% 100%',
  black: '0 0% 0%',
  neutral: {
    50: '0 0% 98%',
    100: '0 0% 96%',
    200: '0 0% 90%',
    300: '0 0% 83%',
    400: '0 0% 64%',
    500: '0 0% 45%',
    600: '0 0% 32%',
    700: '0 0% 25%',
    800: '0 0% 15%',
    900: '0 0% 9%',
    950: '0 0% 4%',
  },
  light: {
    50: '0 0% 99%',
    100: '0 0% 99%',
    200: '0 0% 98%',
    300: '210 17% 98%',
    400: '0 0% 96%',
    500: '0 0% 95%',
    600: '210 17% 95%',
    700: '210 16% 93%',
    800: '210 14% 89%',
    900: '200 10% 88%',
    950: '206 10% 86%',
  },
  dark: {
    50: '0 0% 29%',
    100: '0 0% 24%',
    200: '0 0% 20%',
    300: '0 0% 18%',
    400: '0 0% 13%',
    500: '0 0% 12%',
    600: '240 3% 11%',
    700: '0 0% 11%',
    800: '0 0% 9%',
    900: '0 0% 6%',
    950: '0 0% 3%',
  },
  purple: {
    50: '267 77% 95%',
    100: '264 78% 88%',
    200: '264 79% 80%',
    300: '265 80% 71%',
    400: '266 79% 63%',
    500: '266 78% 56%',
    600: '264 73% 53%',
    700: '266 62% 46%',
    800: '266 62% 39%',
    900: '267 62% 33%',
    950: '273 78% 25%',
  },
  orange: {
    50: '7 58% 94%',
    100: '15 88% 86%',
    200: '15 90% 78%',
    300: '15 90% 70%',
    400: '15 91% 63%',
    500: '15 92% 58%',
    600: '15 83% 55%',
    700: '15 73% 51%',
    800: '15 69% 48%',
    900: '15 69% 42%',
    950: '15 83% 32%',
  },
  green: {
    50: '153 47% 92%',
    100: '150 47% 81%',
    200: '152 48% 69%',
    300: '153 50% 56%',
    400: '158 73% 42%',
    500: '156 100% 34%',
    600: '156 100% 31%',
    700: '155 100% 27%',
    800: '154 100% 24%',
    900: '151 100% 18%',
    950: '152 85% 13%',
  },
  red: {
    50: '0 85% 97%',
    100: '0 93% 94%',
    200: '0 96% 89%',
    300: '0 93% 81%',
    400: '0 90% 70%',
    500: '0 84% 60%',
    600: '0 72% 50%',
    700: '0 73% 41%',
    800: '0 70% 35%',
    900: '0 62% 30%',
    950: '0 74% 15%',
  },
  yellow: {
    50: '47 100% 96%',
    100: '47 96% 88%',
    200: '48 96% 76%',
    300: '45 96% 64%',
    400: '43 96% 56%',
    500: '37 92% 50%',
    600: '32 94% 43%',
    700: '25 90% 37%',
    800: '22 82% 31%',
    900: '21 77% 26%',
    950: '20 91% 14%',
  },
  blue: {
    50: '204 100% 97%',
    100: '204 93% 93%',
    200: '200 94% 86%',
    300: '199 95% 73%',
    400: '198 93% 59%',
    500: '198 88% 48%',
    600: '200 98% 39%',
    700: '201 96% 32%',
    800: '200 89% 27%',
    900: '202 80% 23%',
    950: '204 80% 15%',
  },
} as const
/* eslint-enable @typescript-eslint/naming-convention */

export const brandedColors = {
  ash: '#E5E7EB',
  lightpurple: '#B2A1FF',
  purpledefault: primitiveColors.purple[500],
  darkpurple: primitiveColors.purple[950],
  orange: primitiveColors.orange[500],
  green: primitiveColors.green[300],
}
