import http from '@app/utils/http-client'
import type {User} from '@level3/features/users/types/user'

export const getUser: () => Promise<User> = async () => {
  const response = await http.get<User>('/users/me')
  return response.data
}

export const updateUser: (user: Partial<User>) => Promise<User> = async (
  user,
) => {
  const response = await http.put<User>(`/users/${user.id}`, user)
  return response.data
}
