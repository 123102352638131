import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import {
  CLIENT_SELECT_ROUTE,
  NO_ACCOUNT_ROUTE,
  REPORTS_ROUTE,
  ROLE_TYPE,
  TERMS_OF_SERVICE_ROUTE,
} from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import { Tags } from '@shared/utils/Tags.ts'
import type { RouteLocationNormalized, Router } from 'vue-router'
import type { ClientModel } from '@/clients/models/server/ClientModel.ts'
import { usePermissions } from '@/auth/composables/usePermissions'

interface RouteMeta {
  public?: boolean
  roles?: string[]
}

export function defineMiddleware(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized & { meta: RouteMeta }) => {
    debug('Middleware: isAuthorized')
    if (
      to.meta.public
      || to.name === TERMS_OF_SERVICE_ROUTE
      || to.name === CLIENT_SELECT_ROUTE
      || to.name === NO_ACCOUNT_ROUTE
    ) { return }

    const {
      clientName,
    } = AppSettings

    const {
      level3Enabled,
      level4Enabled,
      isEP,
      isDefaultRole,
      isGenericCustomer,
      isReader,
    } = usePermissions()

    const targetClientName = to.params.clientName || clientName.value

    const myClients = store.getters['user/clients'] || []
    const allClients = store.getters['user/allClients'] || []
    const existingClient: ClientModel | undefined = myClients.find(
      (client: ClientModel) => targetClientName === client.name,
    ) || myClients?.[0]

    const isOFSS = existingClient ? Tags.isOFSS(existingClient) : false
    const currentPath = to.path
    const isInLevel4 = currentPath.startsWith('/app')
    const isInLevel3 = !isInLevel4

    // if user has no role let's open NO_ACCOUNT_ROUTE
    if (isDefaultRole.value) {
      debug('Middleware: isAuthenticated isDefaultRole -> NO_ACCOUNT_ROUTE')
      return { name: NO_ACCOUNT_ROUTE }
    }

    // if user has no any clients let's open the NO_ACCOUNT_ROUTE
    if (!isEP.value && !myClients.length && !allClients.length) {
      debug('Middleware: isAuthorized no clients -> NO_ACCOUNT_ROUTE')
      return { name: NO_ACCOUNT_ROUTE }
    }

    if (isReader.value && !to.meta.roles?.includes(ROLE_TYPE.READER) && isInLevel3) {
      debug('Middleware: Redirecting reader to reports')
      return { name: REPORTS_ROUTE }
    }

    if (isGenericCustomer.value && !to.meta.roles?.includes(ROLE_TYPE.GENERIC_CUSTOMER) && isInLevel3) {
      debug('Middleware: Redirecting generic customer to reports')
      return { name: REPORTS_ROUTE }
    }

    /**
     * BOUNCE OUTSIDERS BY LEVEL
     * These bounces are triggered with native window.location.pathname redirection because
     * Routes from other levels are not registered. A refresh will register the correct paths.
     */
    const level4SafeBounceRoute = '/app'
    const level3SafeBounceRoute = '/'

    // Handle generic customer routing based on client tags
    if (isGenericCustomer.value && existingClient) {
      // Redirect to appropriate level based on client tags
      if (isOFSS && isInLevel3) {
        window.location.pathname = level4SafeBounceRoute
        return false
      }
      else if (!isOFSS && isInLevel4) {
        window.location.pathname = level3SafeBounceRoute
        return false
      }
    }

    if (!level4Enabled.value && to.path.startsWith(level4SafeBounceRoute)) {
      debug('Middleware: isAuthorized level4 not enabled -> level3')
      window.location.pathname = level3SafeBounceRoute
      return false
    }

    if (!level3Enabled.value && !to.path.startsWith(level4SafeBounceRoute)) {
      debug('Middleware: isAuthorized level3 not enabled -> level4')
      window.location.pathname = level4SafeBounceRoute
      return false
    }
  })
}
