<script lang="ts" setup>
import { SHORT_ERROR_MSG } from '@/level4/services/OFEService.ts'

interface Props {
  listData: any[]
  modelValue: NullString
  isDataLoading: boolean
  emptyFilterMessage: string
  noDataMessage: string
  dataErrorMessage: string
  searchPlaceholder: string
  itemsHaveDetail?: boolean
  detailErrorMap?: Map<string, Pick<T, 'error' | 'errorCode'>>
  detailInfoMap?: Map<string, number>
  detailHelperText?: string
}

withDefaults(defineProps<Props>(), { itemsHaveDetail: false, })
const emit = defineEmits(['refresh', 'modelValueChange'])
const currentSelectedValue = ref<string | number>(__props.modelValue)
const search = ref<string>()

function refreshDataClickHandler() {
  emit('refresh')
}

const filteredItemList = computed(() => {
  if (search.value?.length) {
    return __props.listData?.filter(item => item.name?.toLowerCase().includes(search.value.toLowerCase()) || item.description?.toLowerCase().includes(search.value.toLowerCase()))
  }
  return __props.listData
})

function returnDetailText(id: string): string {
  if (__props.itemsHaveDetail) {
    const errorCode = __props.detailErrorMap?.get(id)?.errorCode
    const shortErrorMsg = errorCode && SHORT_ERROR_MSG[errorCode]
    if (errorCode && shortErrorMsg) {
      return shortErrorMsg
    }

    if (__props.detailInfoMap?.get(id) !== undefined) {
      return `${__props.detailInfoMap.value?.get(id) ?? 0} recipients`
    }
    if (__props.detailInfoMap?.get(id) === undefined && currentSelectedValue.value === id) {
      return 'Loading...'
    }
  }

  return ''
}
</script>

<template>
  <div v-if="isDataLoading">
    <NSpin class="mt-4 h-250px w-full" />
  </div>

  <BasicInput v-if="!isDataLoading" v-model="search" type="text" :placeholder="searchPlaceholder" isSearchBar />
  <div v-if="filteredItemList?.length" class="flex flex-col gap-4 mt-6">
    <NScrollbar
      class="max-h-sm rounded bg-gray-50 px-8 shadow-inner"
      trigger="none"
    >
      <NRadioGroup
        v-model:value="currentSelectedValue"
        class="flex flex-col gap-2 py-8 w-full"
        @change="(e) => emit('modelValueChange', currentSelectedValue)"
      >
        <RadioInput
          v-for="item in filteredItemList" :key="`${item.id}_${detailErrorMap?.get(item.id)?.error}_${detailInfoMap?.get(item.id)}`"
          :isSelected="item.id === currentSelectedValue"
          :value="item.id"
          :label="item.name"
          :helperText="returnDetailText(item.id)"
          :errorText="detailErrorMap?.get(item.id)?.error"
          class="bg-white mb-4"
          data-test="radio-input-select-item"
        />
      </NRadioGroup>
    </NScrollbar>
    <div
      class="flex cursor-pointer justify-center text-sm font-medium text-offerfit-bright-purple"
      @click="refreshDataClickHandler"
    >
      <div class="i-solar-refresh-bold mr-2 h-5 w-5" />
      Refresh list
    </div>
  </div>
  <div
    v-if="!filteredItemList?.length && listData?.length && !isDataLoading"
    class="h-[18rem] flex flex-col items-center justify-center gap-2 border-1 border-gray-300 rounded-md mt-4"
  >
    <p class="text-center text-gray-500">
      {{ emptyFilterMessage }}
    </p>
  </div>
  <div v-if="!listData?.length && !isDataLoading" class="h-[18rem] flex flex-col items-center justify-center gap-2 border-1 border-gray-300 rounded-md mt-4">
    <p class="text-center text-gray-500">
      {{ noDataMessage }}
    </p>
  </div>
</template>
