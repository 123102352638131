<script setup lang="ts">
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import AppSettings from '@app/AppSettings.ts'
import { vueQueryClient } from '@app/utils/vueQueryClient.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'
import type { SendClassificationModel } from '@/level4/models/sfmc/SendClassificationModel.ts'

const { experimenterFormState, loadSFMCSendClassifications, updateExperimenterFormState } = useExperimenterFormState()
const { clientName, experimenterName, pipelineName } = AppSettings
const currentSelectedSendClassification = ref<string>(experimenterFormState.value?.selectedSFMCSendClassification)

const loadSendClassificationsQuery = useQuery({
  queryKey: ['get-send-classifications', clientName.value, experimenterName.value, pipelineName.value],
  queryFn: async () => getSFMCSendClassifications(),
  retry: 1,
})

async function getSFMCSendClassifications(): Promise<SendClassificationModel[] | undefined> {
  try {
    return await loadSFMCSendClassifications()
  }
  catch (e: any) {
    handlePortalError(e, { defaultUserErrorText: `Couldn't load send classifications. Please check that the integration data is correct and classifications are defined in SFMC.` })
  }
}

function valueChangeHandler(value: string) {
  currentSelectedSendClassification.value = value
}

async function refreshDataClickHandler() {
  await vueQueryClient.invalidateQueries({ queryKey: ['get-send-classifications', clientName.value, experimenterName.value, pipelineName.value] })
}

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentSelectedSendClassification.value),
  submitHandler: async () => {
    await updateExperimenterFormState({ selectedSFMCSendClassification: currentSelectedSendClassification.value })
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="small" footerSize="full"
    supertitle="Send classifications can be updated in your SFMC instance"
    title="Select your send classification for this experimenter"
  >
    <RadioListSelect
      :modelValue="currentSelectedSendClassification"
      :listData="loadSendClassificationsQuery.data?.value"
      :isDataLoading="loadSendClassificationsQuery.isPending.value"
      searchPlaceholder="Search for more send classifications by name..."
      emptyFilterMessage="No send classifications found"
      noDataMessage="Issue fetching data"
      @refresh="refreshDataClickHandler"
      @modelValueChange="valueChangeHandler"
    />
  </WizardFormPageLayout>
</template>

<style scoped></style>
