<script setup lang="ts">
import { ref } from 'vue'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'

const { experimenterFormState, updateBAUReportingConfiguration, updateExperimenterFormState } = useExperimenterFormState()
const isAddingBauCampaign = ref<boolean>(!!experimenterFormState.value?.isAddingBauCampaign)

const isDeletingBAUConfiguration = computed(() => {
  return !isAddingBauCampaign.value && (!!experimenterFormState.value?.selectedBAUCampaignIds?.length || !!experimenterFormState.value?.selectedBAUAudienceId?.length)
})

defineExpose<FormExposedProperties>({
  submitHandler: async () => {
    await updateExperimenterFormState({ isAddingBauCampaign: isAddingBauCampaign.value })
    if (!isAddingBauCampaign.value) {
      // if not adding BAU, then hit this endpoint to clear configuration, and set values to empty
      await updateBAUReportingConfiguration(isAddingBauCampaign.value, experimenterFormState.value?.selectedBAUCampaignIds, experimenterFormState.value?.selectedBAUAudienceId)
    }
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full"
    headerSize="medium"
    title="Would you like to track a separate business as usual (BAU) campaign's performance in the Reports tab?"
  >
    <div class="flex justify-center p-2">
      <NRadioGroup v-model:value="isAddingBauCampaign">
        <NRadio v-for="item in [{ value: true, label: 'Yes' }, { value: false, label: 'No' }]" :key="item" :value="item.value" :label="item.label" />
      </NRadioGroup>
    </div>
    <div v-if="isDeletingBAUConfiguration" class="flex justify-center mt-4">
      <BlueInfoMessage class="w-80" title="BAU has previously been configured" subtitle="Proceeding will delete the configuration" />
    </div>
  </WizardFormPageLayout>
</template>
