import type { DIMENSION_TYPE, DimensionKey } from '@shared/data/constants'
import type { NullString } from '@shared/utils/Types'

export function getAgentsHParamsObject(dimensionName: string) {
  return [
    {
      agent: 'catboost_oracle',
      hparams: {
        name: `${dimensionName}_simple_catboost_oracle`,
        depth: 6,
        num_trees: 1000,
        l2_leaf_reg: 5,
        learning_rate: 0.01,
        loss_function: 'RMSE',
        reward_params: {
          secondary_coefficient: 0.1,
          use_historical_average: false,
          secondary_coefficient_is_relative: true,
        },
        min_data_in_leaf: 6,
        replay_buffer_dynamic_size_cfg: {
          number_of_periods: 35,
          recalculate_every: 7,
          n_calibration_rounds: 7,
        },
      },
      strategy: 'epsilon_greedy',
      deciding_agent: true,
      strategy_params: {
        schedule: {
          0.5: 0.07,
          0.6: 0.06,
          0.7: 0.05,
          0.8: 0.04,
        },
        top_percent: 1,
        use_relative_schedule: true,
        action_created_date_decay: 0,
        action_created_date_score_min: 0,
      },
    },
  ]
}

export type DecidingAgentName = string

export interface DimensionConfigModel {
  display_name?: string
  description?: NullString
  send_decision_to: string[]
  agents: string[] | 'default' | ReturnType<typeof getAgentsHParamsObject>
  dimension_type?: DIMENSION_TYPE
  action_expiration?: boolean
  action_valid_period?: number
  record_ranks_for_agents?: string[]
  record_shaps_for_agents?: {
    [key: DecidingAgentName]: {
      shap_sample_num: number
    }
  }
  [key: string]: any
}

export interface DimensionConfigModelPartial extends Partial<DimensionConfigModel> {
  name?: DimensionKey | string
  isNew?: boolean
  original?: DimensionConfigModelPartial
}
