<script setup lang="ts">
import {useFieldTypes, type FieldTypeValue} from './useFieldTypes.ts'

type Props = {
  type: FieldTypeValue
  size?: 'xs' | 'sm' | 'base'
  boxed?: boolean
}

withDefaults(defineProps<Props>(), { size: 'xs',boxed: true, })

const {getFieldTypeByType} = useFieldTypes()

const iconData = computed(() => {
  return getFieldTypeByType(__props.type)
})

const icon = computed(() => iconData.value?.icon)
const color = computed(() => iconData.value?.color)
const boxColor = computed(() => iconData.value?.boxColor)

const iconSizeMap = {
  xs: 'h-4 w-4',
  sm: 'h-6 w-6',
  base: 'h-8 w-8',
} as const

const iconSizeClass = computed(() => iconSizeMap[__props.size])
</script>

<template>
  <div
    class="field-type-icon-wrapper rounded-sm p-[2px]"
    :class="[boxed ? boxColor : 'bg-transparent']"
  >
    <div :class="[icon, color, iconSizeClass]" class="field-type-icon" />
  </div>
</template>
