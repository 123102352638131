import type { JobStatus } from '@shared/utils/Types'
import type { MatchingOperator } from '@/filtering/models/FilterModel'
import type { DeploymentsKeys } from '@/deployments/models/server/DeploymentModel'

/* BEFORE ADDING A CONSTANT TO THIS FILE
 *
 * 1. Is this String used somewhere else?
 * 2. If yes, should it be a global constant or a module constant? Could it be associated with a particular feature? (ex: reports, usecases, data assets)
 * 3. Is ALWAYS the purpose of this constant to be used with a particular function (ex: Local Storage, Query Param)
 * 4. If yes, Can we hide the constant from all the app and just export the function + constant? (useStorage, useRouteQuery)
 *
 * */
export const OFFERFIT_DOMAIN = 'offerfit.ai'

export enum LEVEL {
  THREE = 3,
  FOUR = 4,
}

export enum ROLE_TYPE {
  AIE = 'aie', // TODO: rename to OfferFit User
  ADMIN = 'admin',
  EP = 'ep', // TODO: rename to Implementation Partner
  READER = 'reader', // TODO: rename to FOF Customer
  CUSTOMER = 'customer', // TODO: rename to OFSS Customer
  GENERIC_CUSTOMER = 'generic_customer', // User that has access to both level 3 and level 4 features
  DEFAULT = 'authenticated',
}

export const DEFAULT_CSV_SEPARATOR: string = ','
export const DEFAULT_SAMPLE_DATA_ROWS_LIMIT: number = 100

export enum ActionFieldType {
  FEATURE = 'feature',
  PARAMETER = 'parameter',
}

export enum ActionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ChangeStatus {
  ADDED = 'added',
  DELETED = 'deleted',
  CHANGED = 'changed',
  NONE = 'none',
}

export const FEATURE_REWARD_COLUMN: string = 'reward'
export const FEATURE_RANK_COLUMN: string = 'rank'

export const CUSTOMER_ID_COLUMN: string = 'customer_id'
export const OFFERFIT_RECEIVED_TS_COLUMN = 'offerfit_received_ts'
export const OFFERFIT_UPDATED_TS_COLUMN = 'offerfit_updated_ts'

export const DASHBOARD_ROUTE: string = 'Dashboard'
export const PIPELINES_ROUTE: string = 'Pipelines'
export const DASHBOARD_USECASE_ROUTE: string = 'Dashboard use cases'
export const DEPLOYMENTS_ROUTE: string = 'Deployments'
export const NOT_FOUND_ROUTE: string = 'Page not Found'
export const USE_CASE_ROUTE: string = 'Use case'
export const EVENTS_ROUTE: string = 'Event'
export const AUDIENCE_CUSTOMER_GROUPS_ROUTE: string = 'Audience and customer groups'
export const FEATURES_TO_USE_ROUTE: string = 'Features to use'
export const DIMENSIONS_ROUTE: string = 'Decision dimensions'
export const REWARD_ROUTE: string = 'Reward configuration'
export const REWARD_V2_ROUTE: string = 'Rewards'
export const ACTIONS_ROUTE: string = 'Action banks'
export const GUARDRAILS_ROUTE: string = 'Guardrails'
export const OUTPUTS_ROUTE: string = 'Outputs'
export const DATA_ASSET_ROUTE: string = 'Data asset'

export const CUSTOM_REPORTING_ROUTE: string = 'Reporting'
export const DATA_ASSET_VALIDATION_ROUTE: string = 'Validation'
export const DATA_ASSET_TRANSFORMATIONS_ROUTE: string = 'Transformations'
export const DATA_ASSET_DATA_JOINING_ROUTE: string = 'Data Joining'
export const DATA_ASSET_FEATURES_ROUTE: string = 'Features'
export const DATA_ASSETS_ROUTE: string = 'Data assets'
export const REPORTS_ROUTE: string = 'Reports'
export const CONNECTIONS_ROUTE: string = 'Connections'
export const CONFIG_EDITOR_ROUTE: string = 'Config editor'
export const NO_ACCOUNT_ROUTE: string = 'No account'
export const ERROR_ROUTE: string = 'Error'
export const EMAIL_CONFIRMATION_ROUTE: string = 'Verify your email'
export const EMAIL_CONFIRMED_ROUTE: string = 'Email confirmed!'
export const CLIENT_IS_NOT_SUPPORTED: string = 'Client is not supported'
export const MAINTENANCE_ROUTE: string = 'Maintenance'
export const PRODUCT_SELECTION_ROUTE: string = 'SelectProductPage'
export const TERMS_OF_SERVICE_ROUTE: string = 'Terms of service'
export const UNAVAILABLE_ROUTE: string = 'Unavailable'
export const UNAVAILABLE_ROUTE_PATH: string = '/portal-is-offline'
export const CLIENT_SELECT_ROUTE: string = 'Client selection'
export const DAG_LIST_ROUTE: string = 'DAG list'
export const USER_CLIENT_RELATION_ROUTE: string = 'Client user groups'

export const PRIVILEGES_ROUTE: string = 'Privileges'
export const PRIVILEGES_FEATURES_ROUTE: string = 'Features privileges'
export const PRIVILEGES_ROLES_ROUTE: string = 'Roles privileges'
export const PRIVILEGES_CLIENTS_ROUTE: string = 'Client privileges'
export const PRIVILEGES_USERS_ROUTE: string = 'Users privileges'

export const DOCUMENTATION_ROUTE: string = 'Docs'
export const CHANNELS_ROUTE: string = 'Channels'
export const CONVERSION_ROUTE: string = 'Conversion'

export enum PREVIEW_ROUTE {
  RAW_DATA = 'Source data preview',
  PREPROCESSED_DATA = 'Preprocessed data preview',
  JOINED_DATA = 'Joined data preview',
  TRANSFORMED_DATA = 'Transformed data preview',
}

export const LOCAL_STORAGE_CURRENT_CLIENT: string = 'currentClient'
export const LOCAL_STORAGE_CURRENT_PIPELINE: string = 'currentPipeline'
export const LOCAL_STORAGE_CURRENT_USECASE: string = 'currentUsecase'

export const LOCAL_STORAGE_REDIRECT_URL: string = 'redirectURL'

export const LOCAL_STORAGE_LOG_LEVEL: string = 'logs:level'

export const UI_VERSION: string = 'uiVersion'
export const CLIENT_QUERY_PARAM_NAME = 'org'
export const PIPELINE_QUERY_PARAM_NAME = 'pipeline'
export const USECASE_QUERY_PARAM_NAME = 'usecase'

export const REQUIRED_EVENT_MAP_REPLACE_KEY: string = 'REPLACE'

export const DEFAULT_USER_IMG_SRC = '/images/defaultIcon.svg'

export const REGIME_OFFERFIT: string = 'offerfit'
export const PREDICTION_METHOD_OFFERFIT: string = 'offerfit'
export const OFFERFIT_CUSTOMER_GROUP_DISPLAY_NAME: string = 'OfferFit'

export enum AVAILABLE_STATUS {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export enum FEATURES_CONFIG_FIELD {
  CPU_LIMIT = 'cpu_limit',
  MEMORY_LIMIT = 'memory_limit',
  RETRIES = 'retries',
  TIME_BETWEEN_RETRIES = 'time_between_retries',
}

export const POLLING_DELAY = 5000 // ms
export const CLEAR_FORM_DELAY = 500 // ms
export const INPUT_DEBOUNCE_DELAY = 750 // ms
export const SAVE_CONFIG_DELAY: number = 800
export const SAVE_DIMENSIONS_DESCRIPTION_DELAY: number = 1500
export const REQUEST_DEBOUNCE_DELAY: number = 1100
export const GCS_REQUEST_DEBOUNCE_DELAY: number = 1200

export const PREPROCESS_MEMORY_LIMIT: number = 100
export const PREPROCESS_CPU_LIMIT: number = 32

export const REPORTING_START_DATE_OFFSET = 5

export const PYTHON_MS_API_PREFIX = '/proxy/ms-python/api/v1'

export const TASK_STATUS = {
  PENDING: 'PENDING' as JobStatus,
  RUNNING: 'RUNNING' as JobStatus,
  SUCCEEDED: 'SUCCEEDED' as JobStatus,
  DELETED: 'DELETED' as JobStatus,
  FAILED: 'FAILED' as JobStatus,
}

export enum TASK_NAME {
  ENGINEER_FEATURES = 'engineer_features',
  FEATURE_STATISTICS = 'feature_summary_statistics',
  VALIDATION = 'validate_assets',
  PREPROCESS_MPARTICLE = 'preprocess_mparticle',
  PREPROCESS = 'preprocess',
  JOIN_ASSETS = 'join_assets',
  TRANSFORM_ASSETS = 'transform_assets',
  PREDICT = 'predict',
  AUGMENT_RECS = 'filter_and_augment_recs',
  DELIVER_RECS = 'deliver_recs',
}

export enum DIMENSION_TYPE {
  WHAT = 'what',
  HOW = 'how',
  CADENCE = 'cadence',
  FREQUENCY = 'frequency',
  DAYSOFWEEK = 'daysofweek',
}

export enum DIMENSION_TRIGGER {
  CADENCE_RECOMMENDED = 'Cadence recommended',
  FREQUENCY_RECOMMENDED = 'Frequency recommended',
  DAYSOFWEEK_RECOMMENDED = 'Daysofweek recommended',
}

export const DEFAULT_FEATURE_FILTER_MATCHING_OPERATOR: MatchingOperator = 'and'

export enum PreviewDataType {
  TRANSFORMATION = 'transformation',
  JOINING = 'joining',
  PREPROCESS = 'preprocess',
}

export const COLOR_SCHEME: string[] = ['#8336E6', '#58C897', '#F7C032', '#F76532', '#450E72']
export const MODEL_PREF_CHART_PRIMARY_COLOR_SCHEME = ['#8336E6', '#0690ED', '#58C897', '#8381E0', '#DC7CFF', '#FFB9A0', '#94CCFF', '#EB90D7', '#56D79E', '#C6C5C5']
export const MODEL_PREF_CHART_SECONDARY_COLOR_SCHEME = ['#EADAFF', '#CCEAFF', '#DCFAED', '#ECEBFB', '#F7E4FE', '#FFEBE5', '#E2F1FF', '#FFEAFA', '#E2FBEC', '#FAFAFA']
export enum DashboardTabKey {
  USE_CASES = 'use-cases',
  DEPLOYMENTS = 'deployment',
}

export enum AssetTabKey {
  PROPERTIES = 'properties',
  VALIDATE = 'validation',
  PREPROCESS = 'preprocessing',
  DATA_JOINING = 'data-joining',
  FEATURES = 'features',
  TRANSFORMATION = 'transformation',
}

export enum UseCaseTabKey {
  EVENTS = 'events',
  DECISION_DIMENSION = 'dimensions',
  CHANNELS = 'channels',
  CONVERSION = 'conversion',
  REWARD_CONFIGURATION = 'reward-config',
  REWARDS = 'rewards',
  ACTION_BANKS = 'action-banks',
  CUSTOMER_GROUPS = 'customer-groups',
  GUARDRAILS = 'guardrails',
  FEATURES_TO_USE = 'features-to-use',
  OUTPUTS = 'outputs',
  CUSTOM_REPORTING = 'custom-reporting',
}

export enum ReportTabKey {
  USE_CASE_PERFORMANCE = 'usecase-performance',
  INBOUND_DATA_VALIDATION = 'validation-results',
  SEGMENT_REPORT = 'segment-report',
  MODEL_PREFERENCES = 'model-preferences',
  OUTBOUND_MONITORING = 'outbound-monitoring',
  INBOUND_MONITORING = 'inbound-monitoring',
  VALIDIO_ALERTS = 'validio-monitoring',
  SHAP_REPORT = 'shap-report',
  INSIGHTS = 'insights',
  MONITORING = 'monitoring-reports',
  INSIGHT_REPORTS = 'insights-reports',
}

export enum ClientSelectTabKey {
  MY_CLIENTS = 'my_clients',
  ALL_CLIENTS = 'all_clients',
}

export enum ConfigPageTabKey {
  EDITOR = 'editor',
  HISTORY = 'history',
}

export enum JoinDataAssetModalTabKey {
  JOIN_COLUMNS = 'join_keys',
  INCLUDE_COLUMNS = 'include_columns',
}

export enum DiffViewTabKey {
  PIPELINE = 'pipeline',
  USECASE = 'usecase',
  METADATA = 'metadata',
}

export enum ReportingMetrics {
  TARGET_METRIC = 'target',
}

export enum UseCaseStatus {
  LIVE = 'live',
  DRAFT = 'draft',
  PENDING = 'pending',
}

export enum Environment {
  LIVE = 'live',
  DRAFT = 'draft',
  PENDING = 'pending',
}

export const DownloadIconSVG
  = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">\n'
  + '  <path fill-rule="evenodd" d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />\n'
  + '</svg>\n'

export enum DimensionKey {
  CADENCE = 'cadence',
  CHANNEL = 'channel',
  TIME = 'time',
  DEVICE = 'device',
  OFFER = 'offer',
  PLAN = 'plan',
  CREATIVE = 'creative',
  MESSAGE = 'message',
  FREQUENCY = 'frequency',
  DAYSOFWEEK = 'daysofweek',
  CUSTOM = 'custom',
  EMAIL = 'email',
}

export enum EventMatchingKeys {
  REC_ID = 'rec_id',
  ACTION_ID = 'action_id',
  ACTION_PARAMS = 'action_parameters',
  PROXIMITY = 'proximity',
}

export enum EventTypeKey {
  ACTIVATION = 'activation',
  ENGAGEMENT = 'engagement',
  CONVERSION = 'conversion',
  PENALTY = 'penalty',
  CUSTOM = 'custom',
}

export const CONVERSION_TARGET_DISPLAY_NAME = 'Conversions'

export enum CustomerGroupModalTabKey {
  CUSTOMER_SELECTION = 'customer_selection',
  PREDICTION_METHOD = 'prediction_method',
}

export enum PredictionMethodLabel {
  RANDOM_ACTION = 'Random action',
  NO_SEND_ANY_ACTION = 'Do not send recommendations',
  SPECIFIED_ACTION = 'Specified action',
}

export enum PredictionMethodConfigValue {
  RANDOM_ACTION = 'random_selection',
  SPECIFIED_ACTION = 'specified_action_assigned',
}

export enum NoSendAction {
  NO_SEND_ANY_ACTION = 'no_send',
}

export type PredictionMethodValues = PredictionMethodConfigValue | NoSendAction

export enum ActivationEvent {
  RECOMMENDATION_SENT = 'Recommendation sent',
  EMAIL_SENT = 'Email sent',
  SMS_SENT = 'SMS sent',
  PUSH_NOTIFICATION_SENT = 'Push notification sent',
  IN_APP_NOTIFICATION_SENT = 'In app notification sent',
  OUTBOUND_CALL_DIALED = 'Outbound call dialed',
  MULTI_CHANNEL_SENT = 'Multi-channel sent',
  INBOUND_CALL_DIALED = 'Inbound call dialed',
  POP_UP_DISPLAYED = 'Pop-up displayed',
}
export enum EngagementEvent {
  EMAIL_OPENED = 'Email opened',
  EMAIL_CLICKED = 'Email clicked',
  SMS_OPENED = 'SMS opened',
  SMS_CLICKED = 'SMS clicked',
  PUSH_NOTIFICATION_OPENED = 'Push notification opened',
  PUSH_NOTIFICATION_CLICKED = 'Push notification clicked',
  IN_APP_NOTIFICATION_OPENED = 'In app notification opened',
  IN_APP_NOTIFICATION_CLICKED = 'In app notification clicked',
  OUTBOUND_CALL_CONNECTED = 'Outbound call connected',
  INBOUND_CALL_CONNECTED = 'Inbound call connected',
  MULTI_CHANNEL_INTERACTED = 'Multi-channel interacted',
  POP_UP_CLICKED = 'Pop-up clicked',
}
export enum PenaltyEvent {
  EMAIL_UNSUBSCRIBED = 'Email unsubscribed',
  SMS_UNSUBSCRIBED = 'SMS unsubscribed',
  PUSH_NOTIFICATION_UNSUBSCRIBED = 'Push notification unsubscribed',
  IN_APP_NOTIFICATION_UNSUBSCRIBED = 'In app notification unsubscribed',
  OUTBOUND_CALL_UNSUBSCRIBED = 'Outbound call unsubscribed',
  INBOUND_CALL_UNSUBSCRIBED = 'Inbound call unsubscribed',
  MULTI_CHANNEL_UNSUBSCRIBED = 'Multi channel unsubscribed',
  POP_UP_DISABLED = 'Pop-up disabled',
}

export enum ConversionEvent {
  CONVERSION = 'Conversion',
}

export type ChannelEvent = ActivationEvent | EngagementEvent | PenaltyEvent | ConversionEvent

// more explicit type for use in the code, instead of Channels
export enum EventChannel {
  EMAIL = 'Email',
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'Push notification',
  IN_APP_NOTIFICATION = 'In app notification',
  OUTBOUND_CALL = 'Outbound call',
  INBOUND_CALL = 'Inbound call',
  MULTI_CHANNEL = 'Multi channel',
  POP_UP = 'Pop-up',
}

export enum ActivationSuffix {
  SENT = 'sent',
  DIALED = 'dialed',
}

export enum EngagementSuffix {
  CLICKED = 'clicked',
  OPENED = 'opened',
  CONNECTED = 'connected',
  INTERACTED = 'interacted',
}

export enum PenaltySuffix {
  UNSUBSCRIBE = 'unsubscribed',
}

export enum GoalType {
  NUMBER = 'number', // percents
  VALUE = 'value', // finance
}

export enum DataType {
  DATETIME = 'datetime',
  STRING = 'string',
  INTEGER = 'integer',
  FLOAT = 'float',
  DATE = 'date',
  BOOLEAN = 'bool',
  VALUE_LIST = 'value_list',
}

// eslint-disable-next-line no-template-curly-in-string
export const RUN_DATETIME = '${replace_date:%Y-%m-%d %H:%M:%S, arg}'
export const RUN_DATETIME_NAME = 'Run Datetime'

export enum UseCaseKpiMetrics {
  TARGET_PER_CUST = 'target_metric_per_customer',
  CONVERSION_RATE = 'conversion_rate',
  TARGET_PER_CONVERSION = 'target_metric_per_conversion',
}

export enum UseCaseEmailChannelKpiMetrics {
  EMAIL_CLICK_RATE = 'email_clicks_per_customer',
  EMAIL_CLICKS_PER_SEND = 'email_clicks_per_email_send',
  EMAIL_SENDS_PER_CUST = 'email_sends_per_customer',
  UNSUBSCRIBE_RATE = 'email_unsubscribes_per_customer',
  CONVERSIONS_PER_CLICK = 'conversions_per_click',
}

export enum Regimes {
  OFFERFIT = 'offerfit',
  CONTROL = 'control',
  BAU = 'bau',
  HOLDOUT = 'holdout',
}
export const BAU_DISPLAY_NAME = 'BAU'

export enum DeploymentState {
  CANCELED = 'CANCELED',
  RUNNING_TESTS = 'RUNNING_TESTS',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  TESTS_FAILED = 'TESTS_FAILED',
  REVIEW_APPROVED = 'REVIEW_APPROVED',
  REVIEW_DECLINED = 'REVIEW_DECLINED',
  PROMOTED = 'PROMOTED',
  DEPLOYMENT_ERROR = 'DEPLOYMENT_ERROR',
}

export enum Features {
  AGGREGATION = 'agg',
  BOOLEAN = 'bool',
  ONE_HOT = 'ohe',
  ORDINAL = 'ordinal',
  TIME_SINCE = 'time_since',
  BUCKET_TIME = 'bucket_time',
  RATIO = 'ratio',
  COUNT = 'count',
  IDENTITY = 'identity',
}

export const DeploymentsUpdateKeyList: Record<string, DeploymentsKeys> = {
  STATE: 'state',
  IS_ARCHIVED: 'isArchived',
  REVIEW_COMMENT: 'review_message',
  DEPLOYMENT_ERROR: 'deploymentError',
  FAILED_TEST_INFO: 'failedTestInfo',
}

export const PERSONALIZATION_IMPACT_ANALYSIS = 'personalization_impact'

export enum LocalStorageKey {
  OFFERFIT_LEVEL = 'offerfit_level',
  OFFERFIT_PRODUCT = 'offerfit_product',
  DAG_FILTER_PARAMS = 'dag_filter_params',
  OFFERFIT_FONT_FAMILY = 'offerfit_font_family',
  DAG_OPENED = 'dags_opened',
  REWARDS_PRO_VIEW = 'rewards:pro_view',
}

export enum OfferfitProduct {
  EMAIL = 'email',
  FULL = 'full',
}
export enum OfferfitLevel {
  LEVEL_3 = 'level_3',
  LEVEL_4 = 'level_4',
}

export enum ReportingSettingsTabKey {
  EMAIL_SETTINGS = 'email_settings',
  SEGMENT_SETTINGS = 'segment_settings',
  DATE_SETTINGS = 'date_settings',
  DEFAULT_SETTINGS = 'default_settings',
  ADVANCED_SETTINGS = 'advanced_settings',
}

export enum SegmentReportMetricKeys {
  CONVERSIONS = 'conversions_per_customer',
  TARGET_TOTAL = 'target_per_customer',
}

export enum DATA_ASSET_TYPES {
  INCREMENTAL = 'incremental',
  SNAPSHOT = 'snapshot',
}

export enum ASSET_CONNECTION_TYPES {
  BUCKET = 'bucket',
  SAMPLE = 'sample',
}

export const CLIENT_CONNECTIONS_MAX_AMOUNT_OF_KEYS = 5

/**
 * NOTIFICATIONS DRAWER
 */

export const NOTIFICATIONS_DRAWER_WIDTH: number = 650
export const LOCAL_STORAGE_KEY_NOTIFICATIONS_CURRENT_CLIENT_TYPE_FILTER: string = 'NotificationsCurrentPopoverTab'
export const LOCAL_STORAGE_KEY_NOTIFICATIONS_CURRENT_LEVEL_TYPE_FILTER: string = 'NotificationsCurrentLevelFilter'

export enum USER_NOTIFICATIONS_FILTER_CLIENT_TYPE {
  ALL_CLIENTS = 'allClients',
  CURRENT_CLIENT = 'currentClient',
}

export enum PRIVILEGES_TABS {
  FEATURES = 'Features',
  ROLES = 'Roles',
  CLIENTS = 'Clients',
  USERS = 'Users',
}

export enum USER_NOTIFICATIONS_FILTER_PRIORITY_TYPE {
  ALL_PRIORITIES = 'all',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFORMATION = 'info',
}

export const USECASE_ACTION_BANKS_FIELD = 'actionBanks'

export enum REPORTING_ROUTE_NAMES {
  UPLIFT = 'Uplift',
  INBOUND_VALIDATION = 'Inbound data validation',
  SEGMENTS = 'Segments',
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
  INSIGHTS = 'Personalization insights',
  MODEL_PREFERENCES = 'Model preferences',
  SHAPS = 'SHAPs',
  VALIDIO = 'Validio alerts',
}

export const REPORTING_ROUTES_ARRAY = [
  REPORTING_ROUTE_NAMES.UPLIFT,
  REPORTING_ROUTE_NAMES.SEGMENTS,
  REPORTING_ROUTE_NAMES.INBOUND,
  REPORTING_ROUTE_NAMES.OUTBOUND,
  REPORTING_ROUTE_NAMES.INSIGHTS,
  REPORTING_ROUTE_NAMES.MODEL_PREFERENCES,
  REPORTING_ROUTE_NAMES.SHAPS,
  REPORTING_ROUTE_NAMES.VALIDIO,
]

/* BEFORE ADDING A CONSTANT TO THIS FILE
 *
 * 1. Is this String used somewhere else?
 * 2. If yes, should it be a global constant or a module constant? Could it be associated with a particular feature? (ex: reports, usecases, data assets)
 * 3. Is ALWAYS the purpose of this constant to be used with a particular function (ex: Local Storage, Query Param)
 * 4. If yes, Can we hide the constant from all the app and just export the function + constant? (useStorage, useRouteQuery)
 *
 * */
