import {toMerged} from 'es-toolkit'
import {
  type ThemeCssVariableKey,
  type ThemeCssVariables,
  type ThemeCssVariablesVariant,
  themeCssVariableKeys,
  themes,
} from './tokens/semantic.ts'
import type {ColorOptions, PresetOfferfitThemeOptions} from './types.ts'

const offerfitColorPrefix = 'of'
function generateColorCssVariables(color: ThemeCssVariables) {
  return Object.entries(color)
    .map(([key, value]) => {
      if (!themeCssVariableKeys.includes(key as ThemeCssVariableKey)) return ''
      return `--${offerfitColorPrefix}-${key}: ${value};`
    })
    .filter(Boolean)
    .join('\n')
}

function colorCssVariablesStyles(
  lightVariables: string,
  darkVariables: string,
  {
    radius,
    themeName,
    darkSelector,
  }: {
    radius?: number | false
    themeName?: string | false
    darkSelector: string
  },
) {
  return `
${themeName ? `.theme-${themeName}` : ':root'} {
${lightVariables}
${radius ? generateRadiusCssVariables(radius) : ''}
}
${themeName ? `${darkSelector} .theme-${themeName}` : darkSelector} {
${darkVariables}
}`
}

function generateRadiusCssVariables(radius: number) {
  return `--of-radius: ${radius}rem;`
}

function radiusCssVariablesStyles(radius: number) {
  return `
:root {
${generateRadiusCssVariables(radius)}
}
`
}

function getBuiltInTheme(name: string): ThemeCssVariablesVariant {
  const theme = themes.find((t) => t.name === name)
  if (!theme) throw new Error(`Unknown color: ${name}`)
  return {
    name,
    dark: theme.cssVars.dark,
    light: theme.cssVars.light,
  } satisfies ThemeCssVariablesVariant
}

export function getColorTheme(color: ColorOptions): {
  light: ThemeCssVariables
  dark: ThemeCssVariables
  name: string
} {
  // Handle string color name
  if (typeof color === 'string') {
    const theme = getBuiltInTheme(color)
    return {
      name: color,
      light: theme.light,
      dark: theme.dark,
    }
  }

  // Handle color with base theme override
  if ('base' in color) {
    const merged = toMerged(getBuiltInTheme(color.base), color)
    return {
      name: color.base,
      light: merged.light,
      dark: merged.dark,
    }
  }

  // Handle custom theme
  return {
    name: color.name,
    light: color.light,
    dark: color.dark,
  }
}

export function generateCssVariables(
  theme: PresetOfferfitThemeOptions,
  onlyOne = true,
): string {
  if (Array.isArray(theme))
    return theme.map((t) => generateCssVariables(t, false)).join('\n')

  const {color = 'offerfit-v2', radius = 0.5, darkSelector = '.dark'} = theme

  let cssStyle = ''

  if (color) {
    const {light, dark, name} = getColorTheme(color)
    const lightVariables = generateColorCssVariables(light)
    const darkVariables = generateColorCssVariables(dark)

    cssStyle += colorCssVariablesStyles(lightVariables, darkVariables, {
      radius,
      themeName: !onlyOne && name,
      darkSelector,
    })
  } else if (radius) cssStyle += radiusCssVariablesStyles(radius)

  return cssStyle
}
