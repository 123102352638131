<script setup lang="ts">
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import {Bars3Icon, BoltIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {type RouteLocationRaw, useRoute, useRouter} from 'vue-router'
import {NSwitch, NTooltip, type DropdownOption} from 'naive-ui'
import {store} from '@app/store'
import useAccessibility from '@level3/features/accessibility/composables/useAccessibility'
import {useUser} from '@level3/features/users/composables/useUser'
import type {Client} from '@level3/features/clients/types/client'
import {computed, h, ref} from 'vue'
import {usePath} from '@level3/features/navigation/composables/usePath'
import {UserRole} from '@level3/features/users/types/user'

type MenuItem = {
  name: string
  isAvailable: boolean
  to: RouteLocationRaw
  callback?: () => void
}

const route = useRoute()
const router = useRouter()

const {clientSlug} = usePath()
const isActivityDrawerOpened = ref(false)

const {user, isLoadingUser} = useUser()

const hasAccessToDataAssets = computed(() => {
  if (isLoadingUser || !user.value?.role?.type) {
    return false
  }

  return (
    user.value.role.type === UserRole.AIE ||
    user.value.role.type === UserRole.ADMIN ||
    user.value.role.type === UserRole.EP
  )
})
async function handleSignOut() {
  await store.dispatch('user/resetUser')
}

const navigationItems = computed((): MenuItem[] => [
  {
    name: 'Data Assets',
    to: '/data-assets',
    isAvailable: hasAccessToDataAssets.value,
  },
])

const clientList = computed(() => {
  if (!user.value) {
    return []
  }

  return user.value.clients
})

const clientListOptions = computed(() => {
  if (!user.value?.clients) {
    return []
  }

  return user.value.clients.map((client: Client) => ({
    label: client.name,
    value: client.name,
  }))
})

const currentClient = computed(() => {
  if (!clientList.value) {
    return undefined
  }

  return clientList.value.find(
    (client: Client) => client.name === clientSlug.value,
  )
})

async function handleSelectClient(clientName: string) {
  if (!clientList.value) {
    return
  }

  const selectedClient: Client | undefined = clientList.value.find(
    (client: Client) => client.name === clientName,
  )

  if (!selectedClient) {
    return
  }

  await router.push({
    name: 'client-v2',
    params: {clientSlug: selectedClient.name},
  })
}

const {isColorBlindnessModeEnabled, isReadableFontEnabled} = useAccessibility()

const userNavigationList: DropdownOption[] = [
  {
    key: 'terms-of-service',
    label: 'Terms of service',
  },
  {
    type: 'divider',
    key: 'd1',
  },
  {
    key: 'accessibility',
    title: 'Accessibility',
    children: [
      {
        key: 'color-blindness',
        icon: () =>
          h(NSwitch, {
            value: isColorBlindnessModeEnabled.value,
            size: 'small',
            class: 'scale-50',
          }),
        label: () =>
          h(
            NTooltip,
            {},
            {
              trigger: () => h('span', 'Color Blindness Reports'),
              default: () =>
                h(
                  'span',
                  '(BETA) Only available in Model Preferences Report, we are working on making it available everywhere.',
                ),
            },
          ),
      },
      {
        key: 'readable-font',
        label: 'Readable Font',
        icon: () =>
          h(NSwitch, {
            value: isReadableFontEnabled.value,
            size: 'small',
            class: 'scale-50',
          }),
      },
    ],
  },
  {
    type: 'divider',
    key: 'd2',
  },
  {
    key: 'sign-out',
    label: 'Sign out',
  },
]

function handleUserNavigationSelect(key: string) {
  switch (key) {
    case 'terms-of-service': {
      void router.push('/terms-of-service')
      break
    }

    case 'color-blindness': {
      isColorBlindnessModeEnabled.value = !isColorBlindnessModeEnabled.value
      break
    }

    case 'readable-font': {
      isReadableFontEnabled.value = !isReadableFontEnabled.value
      break
    }

    case 'sign-out': {
      void handleSignOut()
      break
    }

    default: {
      console.warn(`Unhandled navigation key: ${key}`)
      break
    }
  }
}

function isRouteActive(to: string) {
  return route.path.includes(to)
}
</script>

<template>
  <div v-if="!isLoadingUser" class="h-14 bg-white/80 backdrop-blur-4">
    <Disclosure v-slot="{open}" as="nav" class="border-b border-gray-100">
      <div class="nav-wrapper">
        <div
          class="mx-auto flex items-center justify-between gap-4 px-6 py-2 container"
        >
          <div class="left-section flex items-center gap-4">
            <div class="max-w-8 flex-shrink-0">
              <RouterLink to="/">
                <OfferfitLogo type="icon" />
              </RouterLink>
            </div>
            <div class="hidden md:block print:!hidden">
              <div v-if="currentClient">
                <RouterLink
                  v-for="item in navigationItems"
                  :key="item.name"
                  :to="item.to"
                >
                  <NButton quaternary>
                    <span
                      class="text-[1.05em] font-500 text-neutral-600"
                      :class="
                        isRouteActive(item.to.toString())
                          ? 'underline underline-offset-5 underline-2 underline-brand-purple-bright/30 font-bold !text-brand-purple-bright'
                          : 'text-gray-700'
                      "
                    >
                      {{ item.name }}
                    </span>
                  </NButton>
                </RouterLink>
              </div>
            </div>
          </div>
          <div class="right-section flex items-center gap-4">
            <ClientSelector
              :selected-client="currentClient?.name"
              :clients="clientListOptions"
              :role="user?.role?.type"
              @update:client="handleSelectClient"
            />
            <div class="hidden md:block print:!hidden">
              <div class="flex items-center">
                <div w-2 />
                <NDropdown
                  v-if="!!user"
                  :options="userNavigationList"
                  :show-arrow="true"
                  min="w-[200px]"
                  placement="bottom-end"
                  @select="handleUserNavigationSelect($event)"
                >
                  <NAvatar
                    round
                    size="small"
                    :src="user?.picture"
                    cursor="pointer"
                    hover="scale-105"
                  />
                </NDropdown>
              </div>
            </div>
          </div>
          <div v-if="!!user" class="flex -mr-2 md:hidden print:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-offset-gray-800"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden print:hidden">
        <div class="px-2 pb-3 pt-2 space-y-1 sm:px-3">
          <RouterLink
            v-for="item in navigationItems"
            :key="item.name"
            v-slot="{isActive}"
            :to="item.to"
          >
            <DisclosureButton
              as="span"
              class="block rounded-md px-3 py-2 text-base font-medium"
              :class="[
                isActive
                  ? 'bg-offerfit-purple-bright text-white'
                  : 'text-gray-400 hover:bg-gray-700 hover:text-white',
              ]"
              :aria-current="isActive ? 'page' : undefined"
            >
              {{ item.name }}
            </DisclosureButton>
          </RouterLink>
        </div>
        <div class="border-t border-gray-700 pb-3 pt-4">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user?.picture" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-white">
                {{ user?.username }}
              </div>
              <div class="text-sm font-medium text-gray-400">
                {{ user?.email }}
              </div>
            </div>
            <button
              type="button"
              class="ml-auto flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-offset-gray-800"
              @click="isActivityDrawerOpened = true"
            >
              <span class="sr-only">View activity</span>
              <BoltIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <ActivityDrawer />
  </div>
</template>
