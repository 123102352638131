import { usePosthogStore } from '@/level4/stores/usePosthogStore'

export function useFeatureFlags() {
  const posthogStore = usePosthogStore()
  const { featureFlags } = storeToRefs(posthogStore)
  const isFOFinOFEEnabled = computed(() => featureFlags.value.includes('enable-ofe-in-fof'))
  const isEmailVariantsGuardrailsEnabled = computed(() => featureFlags.value.includes('email-variants-guardrails'))
  const isCalendarSendsEnabled = computed(() => featureFlags.value.includes('enable-calendar-sends'))
  const isLevel3v2Enabled = computed(() => featureFlags.value.includes('enable-level-3-v2'))
  const isUpliftPValueEnabled = computed(() => featureFlags.value.includes('enable-uplift-report-p-value'))
  const isThemeEditorEnabled = computed(() => featureFlags.value.includes('enable-theme-editor'))
  const isThemeV2Enabled = computed(() => featureFlags.value.includes('theme-v2'))
  return {
    isFOFinOFEEnabled,
    isEmailVariantsGuardrailsEnabled,
    isCalendarSendsEnabled,
    isLevel3v2Enabled,
    isUpliftPValueEnabled,
    isThemeEditorEnabled,
    isThemeV2Enabled,
  }
}
