import {themes} from './tokens/semantic.ts'
import {
  naiveUiOfferfitThemeV2Light,
  naiveUiOfferfitThemeV2Dark,
} from './adapters/naiveUi.ts'
import {presetOfferfit} from './adapters/unocss.ts'

export const builtinColors = themes.map((theme) => theme.name)
export const builtinRadiuses = [0, 0.3, 0.5, 0.75, 1] as const

export const adapters = {
  unocss: {
    presetOfferfit,
  },
  naiveUi: {
    light: naiveUiOfferfitThemeV2Light,
    dark: naiveUiOfferfitThemeV2Dark,
  },
}
