<script setup lang="ts">
import {NSelect} from 'naive-ui'
import FieldTypeIcon from './FieldTypeIcon.vue'
import {
  useFieldTypes,
  type FieldTypeValue,
  type FieldTypeOption,
} from './useFieldTypes.ts'

type Props = {
  omit?: FieldTypeValue[]
  ghost?: boolean
}

withDefaults(defineProps<Props>(), { omit: () => ([]),ghost: false, })

const value = defineModel<FieldTypeValue>()

const {fieldTypeOptions} = useFieldTypes({omit: __props.omit})

const renderLabel = (option: FieldTypeOption) => {
  return h('div', {class: 'flex items-center gap-2 justify-start'}, [
    h(FieldTypeIcon, {type: option.value}),
    h('span', {class: ['font-mono text-xs tracking-tightest']}, option.label),
  ])
}
</script>

<template>
  <NSelect
    v-model:value="value"
    :options="fieldTypeOptions"
    :render-label="renderLabel"
    :consistent-menu-width="false"
    :css-override="ghost ? 'ghost' : undefined"
  />
</template>
