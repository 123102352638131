<script setup lang="ts">
import {h} from 'vue'
import {type DataTableColumn, NTag} from 'naive-ui'
import {RouterLink} from 'vue-router'
import AssetStatusBadge from '@level3/features/dataAssets/components/AssetsList/AssetStatusBadge.vue'
import {type AssetPreview} from '@level3/features/dataAssets/types/asset'
import AssetActions from './AssetActions.vue'

withDefaults(defineProps<{
  clientSlug: string
  pipelineSlug: string
  assetsData: AssetPreview[]
}>(), {  })

const columns: Array<DataTableColumn<AssetPreview>> = [
  {
    title: 'NAME',
    key: 'name',
    render(row) {
      return h('div', [
        h('div', [
          h(
            RouterLink,
            {
              class: 'font-medium text-brand-purple-bright mr-3',
              to: {
                name: 'data-asset-v2',
                params: {
                  clientSlug: __props.clientSlug,
                  pipelineSlug: __props.pipelineSlug,
                  dataAssetSlug: row.name,
                },
              },
            },
            row.name,
          ),
          h(
            NTag,
            {
              bordered: false,
              class: 'bg-gray-50',
              round: true,
              size: 'small',
            },
            {default: () => row.description},
          ),
        ]),
        h('p', {class: 'text-gray-500'}, row.description),
        row.customerPopulationFilter &&
          h('div', {class: 'flex items-center gap-1'}, [
            h('span', {class: 'i-solar-users-group-rounded-bold text-xs'}),
            h(
              'p',
              {class: 'text-sm text-gray-500'},
              `Customer population filter: ${row.customerPopulationFilter}`,
            ),
          ]),
      ])
    },
  },
  {
    title: 'STATUS',
    key: 'status',
    render(row) {
      return h(AssetStatusBadge, {status: row.status})
    },
  },
  {
    title: 'SOURCE',
    key: 'source',
    render(row) {
      return row.source === 'sample_file' ? 'Sample file' : 'GCS file path'
    },
  },
  {
    title: 'DATA JOINS',
    key: 'dataJoins',
    render(row) {
      return h(
        RouterLink,
        {
          to: {
            name: 'data-asset-v2',
            params: {clientSlug: __props.clientSlug, pipelineSlug: __props.pipelineSlug, dataAssetSlug: row.name},
            query: {tab: 'dataJoins'},
          },
          class: 'block w-full h-full',
        },
        Array.isArray(row.dataJoins)
          ? row.dataJoins.map((item) =>
              h(
                NTag,
                {
                  bordered: false,
                  class: 'bg-gray-50',
                  round: true,
                  size: 'small',
                },
                item,
              ),
            )
          : String(row.dataJoins),
      )
    },
  },
  {
    title: 'TRANSFORMATIONS',
    key: 'transformations',
    align: 'center',
    render(row) {
      return h(
        RouterLink,
        {
          to: {
            name: 'data-asset-v2',
            params: {clientSlug: __props.clientSlug, pipelineSlug: __props.pipelineSlug, dataAssetSlug: row.name},
            query: {tab: 'transformation'},
          },
          class: 'block w-full h-full',
        },
        row.transformations,
      )
    },
  },
  {
    title: 'FEATURES',
    key: 'features',
    align: 'center',
    render(row) {
      return h(
        RouterLink,
        {
          to: {
            name: 'data-asset-v2',
            params: {clientSlug: __props.clientSlug, pipelineSlug: __props.pipelineSlug, dataAssetSlug: row.name},
            query: {tab: 'features'},
          },
          class: 'block w-full h-full',
        },
        row.features,
      )
    },
  },
  {
    title: '',
    key: 'actions',
    align: 'center',
    render(row) {
      return h(AssetActions, {
        assetStatus: row.status,
        assetType: row.type,
        assetName: row.name,
      })
    },
  },
]
</script>

<template>
  <NDataTable :columns="columns" :data="assetsData" :scroll-x="1400">
    <template #empty>
      <div class="h-[280px] flex flex-col items-center justify-center gap-2">
        <div
          class="i-custom:data-file-icon mb-2 text-4xl text-brand-purple-bright"
        />
        <p class="text-lg font-medium">Nothing added yet</p>
        <p class="text-sm text-gray-700">
          Your first asset will be the customer population asset.
        </p>
      </div>
    </template>
  </NDataTable>
</template>
