import type { ChannelEvent, PredictionMethodValues } from '@shared/data/constants'
import {
  ASSET_CONNECTION_TYPES,
  ActivationEvent,
  ActivationSuffix,
  ConversionEvent,
  DATA_ASSET_TYPES,
  DIMENSION_TRIGGER,
  DataType,
  DeploymentState,
  DimensionKey,
  EngagementEvent,
  EngagementSuffix,
  EventChannel,
  EventMatchingKeys,
  EventTypeKey,
  Features,
  PenaltyEvent,
  PredictionMethodConfigValue,
  PredictionMethodLabel,
  REQUIRED_EVENT_MAP_REPLACE_KEY,
  Regimes,
  TASK_NAME,
} from '@shared/data/constants'
import type {
  DataAssetFormat,
  FileExtension,
  ItemWithDescription,
  OPERATION_TYPE,
  SelectItem,
  SelectItemWithType,
} from '@shared/utils/Types'
import type { FeatureType } from '@/data-assets/models/FeaturesModel'
import type { CompareOperator, MatchingOperator } from '@/filtering/models/FilterModel'
import type { ChannelEventsSequence } from '@/usecases/models/EventsModel'
import { ActionColumn } from '@/usecases/models/server/ActionModel'

export const COMPARISON_OPERATORS_MAP: Map<CompareOperator, string> = new Map<CompareOperator, string>([
  ['eq', 'equals'],
  ['gt', 'greater than'],
  ['lt', 'less than'],
  ['geq', 'greater than or equals'],
  ['leq', 'less than or equals'],
  ['neq', 'not equals'],
  ['isnull', 'is null'],
  ['notnull', 'is not null'],
  ['isin', 'is in'],
  ['notin', 'not in'],
  ['endswith', 'ends with'],
  ['startswith', 'starts with'],
  ['contains', 'contains'],
  ['notcontains', 'not contains'],
])

export const OPERATOR_REPRESENTATION: Map<CompareOperator, string> = new Map<CompareOperator, string>([
  ['eq', '='],
  ['gt', '>'],
  ['lt', '<'],
  ['geq', '>='],
  ['leq', '<='],
  ['neq', '!='],
  ['isnull', 'is null'],
  ['notnull', 'is not null'],
  ['isin', 'is in'],
  ['notin', 'is not in'],
  ['endswith', 'ends with'],
  ['startswith', 'starts with'],
  ['contains', 'contains'],
  ['notcontains', 'not contains'],
])

function createListItemForOperator(value: CompareOperator) {
  return { value, label: COMPARISON_OPERATORS_MAP.get(value) } as SelectItem
}

/// LISTS

export const MONTHS: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const DAYS: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const DIMENSION_TEMPLATES: SelectItem<DimensionKey>[] = [
  { value: DimensionKey.CADENCE, label: 'Cadence' },
  { value: DimensionKey.CHANNEL, label: 'Channel' },
  { value: DimensionKey.TIME, label: 'Time' },
  { value: DimensionKey.DEVICE, label: 'Device' },
  { value: DimensionKey.OFFER, label: 'Offer' },
  { value: DimensionKey.PLAN, label: 'Plan' },
  { value: DimensionKey.CREATIVE, label: 'Creative' },
  { value: DimensionKey.FREQUENCY, label: 'Frequency' },
  { value: DimensionKey.DAYSOFWEEK, label: 'Days of Week' },
  // TODO: { value: DimensionKey.CUSTOM, label: 'Custom' }
]

export const DIMENSION_HOW_TYPE_VALUES = [
  DimensionKey.CHANNEL,
  DimensionKey.TIME,
  DimensionKey.DEVICE,
  DimensionKey.CREATIVE,
]
export const DIMENSION_WHAT_TYPE_VALUES = [DimensionKey.OFFER, DimensionKey.PLAN]
export const DIMENSION_WHEN_TYPE_VALUES = [DimensionKey.FREQUENCY, DimensionKey.DAYSOFWEEK, DimensionKey.CADENCE]

export const TRANSFORM_DATA_TYPES: SelectItem<DataType>[] = [
  {
    value: DataType.DATETIME,
    label: 'Datetime',
  },
  {
    value: DataType.INTEGER,
    label: 'Integer',
  },
  {
    value: DataType.FLOAT,
    label: 'Float',
  },
  {
    value: DataType.STRING,
    label: 'String',
  },
]

export const ASSET_FIELD_TYPES: SelectItem<DataType>[] = [
  {
    value: DataType.BOOLEAN,
    label: 'Boolean',
  },
  {
    value: DataType.DATE,
    label: 'Date',
  },
  {
    value: DataType.DATETIME,
    label: 'Datetime',
  },
  {
    value: DataType.INTEGER,
    label: 'Integer',
  },
  {
    value: DataType.FLOAT,
    label: 'Float',
  },
  {
    value: DataType.STRING,
    label: 'String',
  },
  {
    value: DataType.VALUE_LIST,
    label: 'Value list',
  },
]

export const TRUE_FALSE_ITEMS: SelectItem<'True' | 'False'>[] = [
  {
    value: 'True',
    label: 'True',
  },
  {
    value: 'False',
    label: 'False',
  },
]

export const ACTIVATION_DELAY_OPTIONS: SelectItem[] = [
  { value: 0, label: 'Same day' },
  { value: 1, label: 'One day later' },
  { value: 2, label: 'Two days later' },
  { value: 3, label: 'Three days later' },
  { value: 4, label: 'Four days later' },
  { value: 5, label: 'Five days later' },
  { value: 6, label: 'Six days later' },
  { value: 7, label: 'One week later' },
]

export const CUSTOMER_GROUPS_FULL_COMPARISON_OPERATORS_LIST: SelectItem<CompareOperator>[] = [
  createListItemForOperator('eq'),
  createListItemForOperator('gt'),
  createListItemForOperator('lt'),
  createListItemForOperator('geq'),
  createListItemForOperator('leq'),
  createListItemForOperator('neq'),
  createListItemForOperator('isnull'),
  createListItemForOperator('notnull'),
  createListItemForOperator('isin'),
  createListItemForOperator('notin'),
  createListItemForOperator('startswith'),
  createListItemForOperator('endswith'),
  createListItemForOperator('contains'),
  createListItemForOperator('notcontains'),
]

export const FULL_COMPARISON_OPERATORS_LIST: SelectItem<CompareOperator>[] = CUSTOMER_GROUPS_FULL_COMPARISON_OPERATORS_LIST

export const FEATURE_TYPE_LIST: SelectItem<FeatureType>[] = [
  { label: 'Aggregation', value: Features.AGGREGATION },
  { label: 'Boolean', value: Features.BOOLEAN },
  { label: 'Count', value: Features.COUNT },
  { label: 'Identity', value: Features.IDENTITY },
  { label: 'Ordinal', value: Features.ORDINAL },
  { label: 'One Hot', value: Features.ONE_HOT },
  { label: 'Ratio', value: Features.RATIO },
  { label: 'Time since', value: Features.TIME_SINCE },
  { label: 'Time bucket', value: Features.BUCKET_TIME },
]

export const SQL_OPERATIONS: SelectItem<OPERATION_TYPE>[] = [
  { label: 'Sum', value: 'sum' },
  { label: 'Average', value: 'average' },
  { label: 'Mode', value: 'mode' },
  { label: 'Standard Deviation', value: 'sdev' },
  { label: 'Max', value: 'max' },
  { label: 'Min', value: 'min' },
  { label: 'Number of Unique', value: 'nunique' },
]

export const FEATURE_OPERATION_TRANSLATION: Map<OPERATION_TYPE, string> = new Map([
  ['average', 'avg'],
  ['sdev', 'stddev'],
])

export const AND_OR_OPTIONS: SelectItem<MatchingOperator>[] = [
  { value: 'and', label: 'all' },
  { value: 'or', label: 'any' },
]

// events
export const EVENT_TYPE_OPTIONS: SelectItem<EventTypeKey>[] = [
  { label: 'Activation event', value: EventTypeKey.ACTIVATION },
  { label: 'Engagement event', value: EventTypeKey.ENGAGEMENT },
  { label: 'Conversion event', value: EventTypeKey.CONVERSION },
  { label: 'Penalty event', value: EventTypeKey.PENALTY },
]

export const EVENT_DEFAULTS = new Map<ChannelEvent, EventTypeKey>([
  [ActivationEvent.EMAIL_SENT, EventTypeKey.ACTIVATION],
  [ActivationEvent.SMS_SENT, EventTypeKey.ACTIVATION],
  [ActivationEvent.INBOUND_CALL_DIALED, EventTypeKey.ACTIVATION],
  [ActivationEvent.OUTBOUND_CALL_DIALED, EventTypeKey.ACTIVATION],
  [ActivationEvent.IN_APP_NOTIFICATION_SENT, EventTypeKey.ACTIVATION],
  [ActivationEvent.PUSH_NOTIFICATION_SENT, EventTypeKey.ACTIVATION],
  [ActivationEvent.MULTI_CHANNEL_SENT, EventTypeKey.ACTIVATION],
  [ActivationEvent.POP_UP_DISPLAYED, EventTypeKey.ACTIVATION],
  [EngagementEvent.EMAIL_OPENED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.EMAIL_CLICKED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.SMS_OPENED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.SMS_CLICKED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.PUSH_NOTIFICATION_OPENED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.PUSH_NOTIFICATION_CLICKED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.IN_APP_NOTIFICATION_OPENED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.IN_APP_NOTIFICATION_CLICKED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.INBOUND_CALL_CONNECTED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.OUTBOUND_CALL_CONNECTED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.MULTI_CHANNEL_INTERACTED, EventTypeKey.ENGAGEMENT],
  [EngagementEvent.POP_UP_CLICKED, EventTypeKey.ENGAGEMENT],
  [PenaltyEvent.EMAIL_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.SMS_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.PUSH_NOTIFICATION_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.IN_APP_NOTIFICATION_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.INBOUND_CALL_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.OUTBOUND_CALL_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.MULTI_CHANNEL_UNSUBSCRIBED, EventTypeKey.PENALTY],
  [PenaltyEvent.POP_UP_DISABLED, EventTypeKey.PENALTY],
  [ConversionEvent.CONVERSION, EventTypeKey.CONVERSION],
])

export const EVENT_SELECT_ITEMS: SelectItemWithType<ChannelEvent, EventTypeKey>[] = Array.from(EVENT_DEFAULTS.entries()).map(
  ([name, type]: [ChannelEvent, EventTypeKey]) => {
    return { label: name, value: name, type }
  },
)

/// MAPS

export const TASK_DISPLAY_NAME_MAP: Map<string, string> = new Map([
  [TASK_NAME.PREPROCESS, 'Preprocessing'],
  [TASK_NAME.VALIDATION, 'Validation'],
  [TASK_NAME.ENGINEER_FEATURES, 'Feature engineering'],
  [TASK_NAME.FEATURE_STATISTICS, 'Feature statistics'],
  [TASK_NAME.PREDICT, 'Predict'],
  [TASK_NAME.JOIN_ASSETS, 'Join assets'],
  [TASK_NAME.TRANSFORM_ASSETS, 'Transform assets'],
  [TASK_NAME.AUGMENT_RECS, 'Augment recs'],
  [TASK_NAME.DELIVER_RECS, 'Deliver recs'],
])

export const CONVERSION_EVENT_RADIO_LABELS: Map<any, string> = new Map([
  [
    `${ActivationSuffix.SENT}`,
    `It's possible that just receiving the ${REQUIRED_EVENT_MAP_REPLACE_KEY} may influence customers to convert.`,
  ],
  [
    `${ActivationSuffix.DIALED}`,
    `It's possible that just receiving the ${REQUIRED_EVENT_MAP_REPLACE_KEY} may influence customers to convert.`,
  ],
  [`${EngagementSuffix.OPENED}`, `Customers just need to open the ${REQUIRED_EVENT_MAP_REPLACE_KEY}.`],
  [`${EngagementSuffix.CLICKED}`, `Customers just need to click on the ${REQUIRED_EVENT_MAP_REPLACE_KEY}.`],
  [`${EngagementSuffix.CONNECTED}`, `Customers just need to connect to the ${REQUIRED_EVENT_MAP_REPLACE_KEY}.`],
  [`${EngagementSuffix.INTERACTED}`, `Customers just need to interact with the ${REQUIRED_EVENT_MAP_REPLACE_KEY}.`],
])

export const channelEventsMap = new Map<EventChannel, Array<ChannelEvent>>([
  [
    EventChannel.EMAIL,
    [ActivationEvent.EMAIL_SENT, EngagementEvent.EMAIL_OPENED, EngagementEvent.EMAIL_CLICKED, PenaltyEvent.EMAIL_UNSUBSCRIBED],
  ],
  [
    EventChannel.SMS,
    [ActivationEvent.SMS_SENT, EngagementEvent.SMS_OPENED, EngagementEvent.SMS_CLICKED, PenaltyEvent.SMS_UNSUBSCRIBED],
  ],
  [
    EventChannel.OUTBOUND_CALL,
    [ActivationEvent.OUTBOUND_CALL_DIALED, EngagementEvent.OUTBOUND_CALL_CONNECTED, PenaltyEvent.OUTBOUND_CALL_UNSUBSCRIBED],
  ],
  [
    EventChannel.INBOUND_CALL,
    [ActivationEvent.INBOUND_CALL_DIALED, EngagementEvent.INBOUND_CALL_CONNECTED, PenaltyEvent.INBOUND_CALL_UNSUBSCRIBED],
  ],
  [
    EventChannel.PUSH_NOTIFICATION,
    [
      ActivationEvent.PUSH_NOTIFICATION_SENT,
      EngagementEvent.PUSH_NOTIFICATION_OPENED,
      EngagementEvent.PUSH_NOTIFICATION_CLICKED,
      PenaltyEvent.PUSH_NOTIFICATION_UNSUBSCRIBED,
    ],
  ],
  [
    EventChannel.IN_APP_NOTIFICATION,
    [
      ActivationEvent.IN_APP_NOTIFICATION_SENT,
      EngagementEvent.IN_APP_NOTIFICATION_OPENED,
      EngagementEvent.IN_APP_NOTIFICATION_CLICKED,
      PenaltyEvent.IN_APP_NOTIFICATION_UNSUBSCRIBED,
    ],
  ],
  [
    EventChannel.MULTI_CHANNEL,
    [ActivationEvent.MULTI_CHANNEL_SENT, EngagementEvent.MULTI_CHANNEL_INTERACTED, PenaltyEvent.MULTI_CHANNEL_UNSUBSCRIBED],
  ],
  [
    EventChannel.POP_UP,
    [ActivationEvent.POP_UP_DISPLAYED, EngagementEvent.POP_UP_CLICKED, PenaltyEvent.POP_UP_DISABLED],
  ],
])

export const ChannelActivationEventMap: Map<EventChannel, ActivationEvent> = new Map([
  [EventChannel.EMAIL, ActivationEvent.EMAIL_SENT],
  [EventChannel.SMS, ActivationEvent.SMS_SENT],
  [EventChannel.OUTBOUND_CALL, ActivationEvent.OUTBOUND_CALL_DIALED],
  [EventChannel.INBOUND_CALL, ActivationEvent.INBOUND_CALL_DIALED],
  [EventChannel.PUSH_NOTIFICATION, ActivationEvent.PUSH_NOTIFICATION_SENT],
  [EventChannel.IN_APP_NOTIFICATION, ActivationEvent.IN_APP_NOTIFICATION_SENT],
  [EventChannel.MULTI_CHANNEL, ActivationEvent.MULTI_CHANNEL_SENT],
  [EventChannel.POP_UP, ActivationEvent.POP_UP_DISPLAYED],
])

export const ChannelEngagementEventMap: Map<EventChannel, EngagementEvent[]> = new Map([
  [EventChannel.EMAIL, [EngagementEvent.EMAIL_CLICKED, EngagementEvent.EMAIL_OPENED]],
  [EventChannel.SMS, [EngagementEvent.SMS_CLICKED, EngagementEvent.SMS_OPENED]],
  [EventChannel.OUTBOUND_CALL, [EngagementEvent.OUTBOUND_CALL_CONNECTED]],
  [EventChannel.INBOUND_CALL, [EngagementEvent.INBOUND_CALL_CONNECTED]],
  [EventChannel.PUSH_NOTIFICATION, [EngagementEvent.PUSH_NOTIFICATION_CLICKED, EngagementEvent.PUSH_NOTIFICATION_OPENED]],
  [EventChannel.IN_APP_NOTIFICATION, [EngagementEvent.IN_APP_NOTIFICATION_CLICKED, EngagementEvent.IN_APP_NOTIFICATION_OPENED]],
  [EventChannel.MULTI_CHANNEL, [EngagementEvent.MULTI_CHANNEL_INTERACTED]],
  [EventChannel.POP_UP, [EngagementEvent.POP_UP_CLICKED]],
])

export const ChannelPenaltyEventMap: Map<EventChannel, PenaltyEvent> = new Map([
  [EventChannel.EMAIL, PenaltyEvent.EMAIL_UNSUBSCRIBED],
  [EventChannel.SMS, PenaltyEvent.SMS_UNSUBSCRIBED],
  [EventChannel.OUTBOUND_CALL, PenaltyEvent.OUTBOUND_CALL_UNSUBSCRIBED],
  [EventChannel.INBOUND_CALL, PenaltyEvent.INBOUND_CALL_UNSUBSCRIBED],
  [EventChannel.PUSH_NOTIFICATION, PenaltyEvent.PUSH_NOTIFICATION_UNSUBSCRIBED],
  [EventChannel.IN_APP_NOTIFICATION, PenaltyEvent.IN_APP_NOTIFICATION_UNSUBSCRIBED],
  [EventChannel.MULTI_CHANNEL, PenaltyEvent.MULTI_CHANNEL_UNSUBSCRIBED],
  [EventChannel.POP_UP, PenaltyEvent.POP_UP_DISABLED],
])

export const DimensionDefaultTriggerMap: Map<DimensionKey, DIMENSION_TRIGGER> = new Map([
  [DimensionKey.CADENCE, DIMENSION_TRIGGER.CADENCE_RECOMMENDED],
  [DimensionKey.DAYSOFWEEK, DIMENSION_TRIGGER.DAYSOFWEEK_RECOMMENDED],
  [DimensionKey.FREQUENCY, DIMENSION_TRIGGER.FREQUENCY_RECOMMENDED],
])

export const fileExtensionsMap: Map<DataAssetFormat, FileExtension> = new Map<DataAssetFormat, FileExtension>([
  ['csv', 'csv'],
  ['csv.gz', 'csv.gz'],
  ['parquet', 'parquet'],
  ['mparticle', 'txt'],
])

export const PREDICTION_METHOD_OPTIONS_MAP: SelectItemWithType<PredictionMethodValues, PredictionMethodConfigValue>[] = [
  {
    label: PredictionMethodLabel.RANDOM_ACTION,
    value: PredictionMethodConfigValue.RANDOM_ACTION,
    type: PredictionMethodConfigValue.RANDOM_ACTION,
  } as SelectItemWithType<PredictionMethodValues, PredictionMethodConfigValue>,
  {
    label: PredictionMethodLabel.SPECIFIED_ACTION,
    value: PredictionMethodConfigValue.SPECIFIED_ACTION,
    type: PredictionMethodConfigValue.SPECIFIED_ACTION,
  } as SelectItemWithType<PredictionMethodValues, PredictionMethodConfigValue>,
]

export const CADENCE_TEMPLATE_COLUMNS: ActionColumn[] = [
  ActionColumn.MONDAY,
  ActionColumn.TUESDAY,
  ActionColumn.WEDNESDAY,
  ActionColumn.THURSDAY,
  ActionColumn.FRIDAY,
  ActionColumn.SATURDAY,
  ActionColumn.SUNDAY,
]

export enum STANDARD_ASSET_COLUMNS {
  OFFERFIT_RECEIVED = 'offerfit_received_ts',
  OFFERFIT_UPDATED = 'offerfit_updated_ts',
  CUSTOMER_ID = 'customer_id',
}

export const STANDARD_ASSET_COLUMNS_TYPE_MAP: Map<STANDARD_ASSET_COLUMNS | string, DataType> = new Map([
  [STANDARD_ASSET_COLUMNS.OFFERFIT_RECEIVED, DataType.DATETIME],
  [STANDARD_ASSET_COLUMNS.OFFERFIT_UPDATED, DataType.DATETIME],
  [STANDARD_ASSET_COLUMNS.CUSTOMER_ID, DataType.STRING],
])

export const CONTROL_GROUP_OPTIONS: SelectItem[] = [
  { label: 'Control', value: Regimes.CONTROL },
  { label: 'Holdout', value: Regimes.HOLDOUT },
  { label: 'Business as Usual (BAU)', value: Regimes.BAU },
]

export const CONTROL_GROUP_OPTIONS_WITH_OFFERFIT: SelectItem[] = CONTROL_GROUP_OPTIONS.concat([
  { label: 'OfferFit', value: Regimes.OFFERFIT },
])

export const GROUP_TYPE_DEFINITIONS: Map<Regimes, string> = new Map([
  [
    Regimes.CONTROL,
    'Customers in this group receive either (1) random recommendation based on the available actions, or (2) a specified action.',
  ],
  [Regimes.HOLDOUT, 'Customers in this group receive no communications.'],
  [
    Regimes.BAU,
    'Customers in this group receive no recommendations from OfferFit, but may receive communications separately from the company, not through OfferFit.',
  ],
])

export const DisplayRegimes: Map<Regimes, string> = new Map([
  [Regimes.OFFERFIT, 'OfferFit'],
  [Regimes.HOLDOUT, 'Holdout'],
  [Regimes.CONTROL, 'Control'],
  [Regimes.BAU, 'Business as Usual (BAU)'],
])

export interface DeploymentStateMapDict {
  displayName: string
  type: string
}

export const DeploymentStateDisplayMap: Map<DeploymentState, DeploymentStateMapDict> = new Map([
  [DeploymentState.TESTS_FAILED, { displayName: 'Failed tests', type: 'error' }],
  [DeploymentState.RUNNING_TESTS, { displayName: 'Running tests', type: 'warning' }],
  [DeploymentState.AWAITING_REVIEW, { displayName: 'Awaiting review', type: 'primary' }],
  [DeploymentState.DEPLOYMENT_ERROR, { displayName: 'Deployment error', type: 'error' }],
  [DeploymentState.CANCELED, { displayName: 'Canceled', type: 'warning' }],
  [DeploymentState.REVIEW_APPROVED, { displayName: 'Approved', type: 'success' }],
  [DeploymentState.REVIEW_DECLINED, { displayName: 'Declined', type: 'error' }],
  [DeploymentState.PROMOTED, { displayName: 'Deployed', type: 'success' }],
])

export const EventChannelMap = new Map<ChannelEvent, EventChannel>([
  [ActivationEvent.EMAIL_SENT, EventChannel.EMAIL],
  [EngagementEvent.EMAIL_OPENED, EventChannel.EMAIL],
  [EngagementEvent.EMAIL_CLICKED, EventChannel.EMAIL],
  [PenaltyEvent.EMAIL_UNSUBSCRIBED, EventChannel.EMAIL],
  [ActivationEvent.OUTBOUND_CALL_DIALED, EventChannel.OUTBOUND_CALL],
  [EngagementEvent.OUTBOUND_CALL_CONNECTED, EventChannel.OUTBOUND_CALL],
  [PenaltyEvent.OUTBOUND_CALL_UNSUBSCRIBED, EventChannel.OUTBOUND_CALL],
  [ActivationEvent.INBOUND_CALL_DIALED, EventChannel.INBOUND_CALL],
  [EngagementEvent.INBOUND_CALL_CONNECTED, EventChannel.INBOUND_CALL],
  [PenaltyEvent.INBOUND_CALL_UNSUBSCRIBED, EventChannel.INBOUND_CALL],
  [ActivationEvent.SMS_SENT, EventChannel.SMS],
  [EngagementEvent.SMS_OPENED, EventChannel.SMS],
  [EngagementEvent.SMS_CLICKED, EventChannel.SMS],
  [PenaltyEvent.SMS_UNSUBSCRIBED, EventChannel.SMS],
  [ActivationEvent.IN_APP_NOTIFICATION_SENT, EventChannel.IN_APP_NOTIFICATION],
  [EngagementEvent.IN_APP_NOTIFICATION_OPENED, EventChannel.IN_APP_NOTIFICATION],
  [EngagementEvent.IN_APP_NOTIFICATION_CLICKED, EventChannel.IN_APP_NOTIFICATION],
  [PenaltyEvent.IN_APP_NOTIFICATION_UNSUBSCRIBED, EventChannel.IN_APP_NOTIFICATION],
  [ActivationEvent.PUSH_NOTIFICATION_SENT, EventChannel.PUSH_NOTIFICATION],
  [EngagementEvent.PUSH_NOTIFICATION_OPENED, EventChannel.PUSH_NOTIFICATION],
  [EngagementEvent.PUSH_NOTIFICATION_CLICKED, EventChannel.PUSH_NOTIFICATION],
  [PenaltyEvent.PUSH_NOTIFICATION_UNSUBSCRIBED, EventChannel.PUSH_NOTIFICATION],
  [ActivationEvent.MULTI_CHANNEL_SENT, EventChannel.MULTI_CHANNEL],
  [EngagementEvent.MULTI_CHANNEL_INTERACTED, EventChannel.MULTI_CHANNEL],
  [PenaltyEvent.MULTI_CHANNEL_UNSUBSCRIBED, EventChannel.MULTI_CHANNEL],
  [ActivationEvent.POP_UP_DISPLAYED, EventChannel.POP_UP],
  [EngagementEvent.POP_UP_CLICKED, EventChannel.POP_UP],
  [PenaltyEvent.POP_UP_DISABLED, EventChannel.POP_UP],
])

export const EventSequences: ChannelEventsSequence[] = [
  {
    channel: EventChannel.EMAIL,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.EMAIL_SENT,
      EngagementEvent.EMAIL_OPENED,
      EngagementEvent.EMAIL_CLICKED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.INBOUND_CALL,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.INBOUND_CALL_DIALED,
      EngagementEvent.INBOUND_CALL_CONNECTED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.OUTBOUND_CALL,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.OUTBOUND_CALL_DIALED,
      EngagementEvent.OUTBOUND_CALL_CONNECTED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.SMS,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.SMS_SENT,
      EngagementEvent.SMS_OPENED,
      EngagementEvent.SMS_CLICKED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.IN_APP_NOTIFICATION,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.IN_APP_NOTIFICATION_SENT,
      EngagementEvent.IN_APP_NOTIFICATION_OPENED,
      EngagementEvent.IN_APP_NOTIFICATION_CLICKED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.PUSH_NOTIFICATION,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.PUSH_NOTIFICATION_SENT,
      EngagementEvent.PUSH_NOTIFICATION_OPENED,
      EngagementEvent.PUSH_NOTIFICATION_CLICKED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.MULTI_CHANNEL,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.MULTI_CHANNEL_SENT,
      EngagementEvent.MULTI_CHANNEL_INTERACTED,
      ConversionEvent.CONVERSION,
    ],
  },
  {
    channel: EventChannel.POP_UP,
    sequence: [
      ActivationEvent.RECOMMENDATION_SENT,
      ActivationEvent.POP_UP_DISPLAYED,
      EngagementEvent.POP_UP_CLICKED,
      ConversionEvent.CONVERSION,
    ],
  },
]

export const matchByOptions: SelectItem<EventMatchingKeys>[] = [
  {
    label: 'Recommendation id (most exact)',
    value: EventMatchingKeys.REC_ID,
  },
  {
    label: 'Action id (very exact)',
    value: EventMatchingKeys.ACTION_ID,
  },
  {
    label: 'Action Parameters (pretty exact)',
    value: EventMatchingKeys.ACTION_PARAMS,
  },
  {
    label: 'Proximity (less exact)',
    value: EventMatchingKeys.PROXIMITY,
  },
]

export const channelListOptions: ItemWithDescription<EventChannel>[] = [
  {
    label: 'Email',
    value: EventChannel.EMAIL,
    description: 'Emails sent from an email management system or emails managed by your team.',
  },
  {
    label: 'SMS',
    value: EventChannel.SMS,
    description: 'Data from your text sending software that tracks the user interactions with those texts.',
  },
  {
    label: 'Push notification',
    value: EventChannel.PUSH_NOTIFICATION,
    description: 'Data from your social media engagement, traffic and advertising.',
  },
  {
    label: 'In app notification',
    value: EventChannel.IN_APP_NOTIFICATION,
    description: undefined,
  },
  {
    label: 'Inbound call',
    value: EventChannel.INBOUND_CALL,
    description: undefined,
  },
  {
    label: 'Outbound call',
    value: EventChannel.OUTBOUND_CALL,
    description: undefined,
  },
  {
    label: 'Multi channel',
    value: EventChannel.MULTI_CHANNEL,
    description: undefined,
  },
  {
    label: 'Pop-up',
    value: EventChannel.POP_UP,
    description: undefined,
  },
]

export const AssetTypeOptions = [
  { value: DATA_ASSET_TYPES.INCREMENTAL, label: 'Incremental' },
  { value: DATA_ASSET_TYPES.SNAPSHOT, label: 'Snapshot' },
]

export const DataConnectionTypeOptions = [
  { value: ASSET_CONNECTION_TYPES.BUCKET, label: 'File path format' },
  { value: ASSET_CONNECTION_TYPES.SAMPLE, label: 'Sample upload' },
]
