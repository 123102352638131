<script setup lang="ts">
import {ref} from 'vue'
import NavigationBar from '@level3/features/navigation/components/NavigationBar.vue'
import PageHeader from '@level3/features/navigation/components/PageHeader.vue'

const title = ref('')
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <NavigationBar />

    <PageHeader :title="title">
      <template v-if="$slots['header-content']" #header-content>
        <slot name="header-content" />
      </template>
      <slot name="header-right" />
    </PageHeader>

    <main
      class="main-container flex flex-1 flex-col px-4 print:max-w-none lg:px-8 sm:px-6 print:!px-0"
    >
      <router-view class="flex-1" />
    </main>
  </div>
  <!-- <ThePageFooter /> -->
</template>
