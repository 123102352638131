<script setup lang="ts">
type Props = {
  values: string[]
  max?: number
}

withDefaults(defineProps<Props>(), { values: () => ([]),max: 3, })

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
const visibleValues = computed(() => __props.values.slice(0, __props.max))

// eslint-disable-next-line @typescript-eslint/no-unsafe-return , @typescript-eslint/no-unsafe-call
const hiddenValues = computed(() => __props.values.slice(__props.max))
const hasHiddenValues = computed(() => hiddenValues.value.length > 0)
</script>

<template>
  <div class="flex flex-wrap gap-1">
    <NTag
      v-for="value in visibleValues"
      :key="value"
      bordered
      size="small"
      round
    >
      <NEllipsis
        :style="{
          maxWidth: '25ch',
        }"
      >
        {{ value }}
      </NEllipsis>
    </NTag>
    <NTooltip v-if="hasHiddenValues" placement="bottom">
      <template #trigger>
        <NTag size="small" round> +{{ hiddenValues.length }} more </NTag>
      </template>
      <ul class="list-disc list-inside space-y-1">
        <li v-for="value in hiddenValues" :key="value">{{ value }}</li>
      </ul>
    </NTooltip>
  </div>
</template>
