<script lang="ts" setup>
import {UserRole} from '@level3/features/users/types/user'
import {NSelect} from 'naive-ui'
import {computed} from 'vue'

type Props = {
  clients: Array<{label: string; value: string}>
  role?: UserRole
}

const props = defineProps<Props>()

const currentClient = defineModel<string | undefined>('selectedClient')

const hasAccessToClientManagement = computed(
  () => props.role === UserRole.ADMIN || props.role === UserRole.AIE,
)
</script>

<template>
  <NSelect
    v-model:value="currentClient"
    placeholder="Select client"
    filterable
    :options="clients"
    :consistent-menu-width="false"
  >
    <template #action>
      <div class="actions flex justify-between">
        <RouterLink :to="{name: 'clients-v2'}">
          <NButton quaternary size="tiny" class="!text-gray-600">
            <template #icon>
              <div class="i-solar-hamburger-menu-outline" />
            </template>
            Clients List
          </NButton>
        </RouterLink>
        <RouterLink
          v-if="hasAccessToClientManagement"
          :to="{name: 'manage-clients-v2'}"
        >
          <NButton quaternary size="tiny" class="!text-gray-600">
            <template #icon>
              <div class="i-solar-add-circle-outline" />
            </template>
            Manage Clients
          </NButton>
        </RouterLink>
      </div>
    </template>
  </NSelect>
</template>
