<script setup lang="ts">
import {
  NModal,
  NForm,
  NFormItem,
  NButton,
  NSwitch,
  type DataTableColumns,
} from 'naive-ui'
import PrimitiveFieldTypeSelect from '@level3/components/FieldType/PrimitiveFieldTypeSelect.vue'
import {type FieldTypeValue} from '@level3/components/FieldType/useFieldTypes'

type ColumnData = {
  name: string
  description: string
  gbqColumnName: string
  fieldType: FieldTypeValue | ''
  sampleValues: string[]
  isDropped?: boolean
}

type Props = {
  columns: DataTableColumns<ColumnData>
  selectedRows: ColumnData[]
}

withDefaults(defineProps<Props>(), {  })
const emit = defineEmits<{
  save: [void]
}>()

const show = defineModel<boolean>('show')

// Bulk edit state
const bulkFieldType = ref<FieldTypeValue>()
const bulkIsDropped = ref<boolean>()

onMounted(setInitialValues)
whenever(ref(__props.selectedRows), setInitialValues, {deep: true})

function setInitialValues() {
  const firstRow = __props.selectedRows[0]
  if (firstRow) {
    if (__props.selectedRows.every((row) => row.fieldType === firstRow.fieldType)) {
      bulkFieldType.value = firstRow.fieldType || undefined
    }

    if (__props.selectedRows.every((row) => row.isDropped === firstRow.isDropped)) {
      bulkIsDropped.value = firstRow.isDropped
    }
  }
}

function handleSave() {
  // Apply bulk changes to all selected rows
  for (const row of __props.selectedRows) {
    if (bulkFieldType.value) row.fieldType = bulkFieldType.value
    if (bulkIsDropped.value !== undefined) row.isDropped = bulkIsDropped.value
  }

  emit('save')
  show.value = false
}
</script>

<template>
  <NModal
    v-model:show="show"
    preset="card"
    class="max-w-lg"
    :bordered="false"
    role="dialog"
    aria-modal="true"
    :title="`Bulk Edit ${selectedRows.length} Columns`"
    :segmented="{content: true}"
  >
    <div class="mb-4 text-sm text-muted-foreground">
      Changes will be applied to all {{ selectedRows.length }} selected columns.
      Leave a field empty to keep its current value.
    </div>

    <NForm label-placement="left" label-width="120">
      <NFormItem label="Field type">
        <PrimitiveFieldTypeSelect
          v-model="bulkFieldType"
          placeholder="Select field type"
          :omit="['all']"
        />
      </NFormItem>

      <NFormItem label="Drop column">
        <NSwitch v-model:value="bulkIsDropped" size="small" />
      </NFormItem>
    </NForm>

    <template #footer>
      <div class="flex justify-end gap-2">
        <NButton @click="show = false">Cancel</NButton>
        <NButton type="primary" @click="handleSave">Save Changes</NButton>
      </div>
    </template>
  </NModal>
</template>
