<script setup lang="ts">
import 'ninja-keys'
import useOfferfitEnvironment from '@app/composables/useOfferfitEnvironment'
import AppSettings from '@app/AppSettings'
import { getMenuItems } from '@shared/utils/hotkeys'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { handleMockReportingFileUpload } from '@app/utils/mockReportingData'
import AppTemplate from '@app/components/AppTemplate.vue'
import { globalThemeOverrides } from '@shared/utils/offerfitTheme'
import { adapters } from '../theme/index'
import {
  hasAcknowledgedLocalRemoteConflictUseCase,
  useCaseHasLocalRemoteConflict,
} from '@/usecases/composables/useUseCaseConfig'
import {
  hasAcknowledgedLocalRemoteConflict,
  pipelineHasLocalRemoteConflict,
} from '@/pipelines/composables/usePipelineConfig'
import { isModalOpened } from '@/data-assets/composables/useMetadataChangeModal'
import { useClientConfig } from '@/clients/composables/useClientConfig'
import { usePermissions } from '@/auth/composables/usePermissions'
import { usePosthogStore } from '@/level4/stores/usePosthogStore'
import { useFeatureFlags } from '@/level4/composables/useFeatureFlags'

useOfferfitEnvironment()
usePosthogStore()

const { loadClientData } = useClientConfig()
const { isAIEorAdmin, isLevel3, isLevel4, isOfferFitUser } = usePermissions()
const isLoadingOverlayOnScreen = ref<boolean>(true)
const isHiddenMenuRendered = ref<boolean>(false)
const hasLoadedClientData = ref<boolean>(false)
const isDark = useDark({
  initialValue: 'light',
  storageKey: 'of/dark_mode',
})
const { isThemeEditorEnabled, isThemeV2Enabled } = useFeatureFlags()
const theme = computed(() => {
  const themeSystem = isThemeV2Enabled.value
    ? { light: adapters.naiveUi.light, dark: adapters.naiveUi.dark }
    : { light: globalThemeOverrides, dark: globalThemeOverrides }
  return isDark.value ? themeSystem.dark : themeSystem.light
})

const showPipelineConflictModal = computed<boolean>(() => {
  const pipelineHasUnacknowledgedConflict = pipelineHasLocalRemoteConflict.value && !hasAcknowledgedLocalRemoteConflict.value
  const useCaseHasUnacknowledgedConflict
    = useCaseHasLocalRemoteConflict.value && !hasAcknowledgedLocalRemoteConflictUseCase.value

  return pipelineHasUnacknowledgedConflict || useCaseHasUnacknowledgedConflict
})

const { clientName, pipelineName, isLevel4Route } = AppSettings
watch([clientName, pipelineName], async ([clientName, pipelineName]) => {
  if (isLevel4Route.value && clientName && pipelineName) {
    try {
      await loadClientData(clientName, pipelineName)
    }
    finally {
      hasLoadedClientData.value = true
    }
  }
  else {
    hasLoadedClientData.value = true
  }
}, { immediate: true })

onMounted(() => {
  nextTick(() => {
    isLoadingOverlayOnScreen.value = false
    isHiddenMenuRendered.value = true
  })
})
</script>

<template>
  <NConfigProvider :themeOverrides="theme" inlineThemeDisabled preflightStyleDisabled :class="isThemeV2Enabled ? 'theme-v2' : 'theme-v1'">
    <NThemeEditor v-if="isThemeEditorEnabled">
      <AppTemplate
        :hasLoadedClientData="hasLoadedClientData"
        :showPipelineConflictModal="showPipelineConflictModal"
        :isModalOpened="isModalOpened"
      />
    </NThemeEditor>
    <AppTemplate
      v-else
      :hasLoadedClientData="hasLoadedClientData"
      :showPipelineConflictModal="showPipelineConflictModal"
      :isModalOpened="isModalOpened"
    />
  </NConfigProvider>

  <ninja-keys
    v-if="((isLevel4 && isOfferFitUser) || (isLevel3 && isAIEorAdmin)) && isHiddenMenuRendered"
    placeholder="Search" :data="getMenuItems()"
    :class="isDark ? 'dark' : 'light'"
  />

  <input
    id="mock-reporting-file-upload"
    name="mock-reporting-file-upload"
    type="file"
    accept="text/csv"
    class="hidden"
    @change="handleMockReportingFileUpload"
  >

  <VueQueryDevtools />
</template>

<style scoped>
ninja-keys {
  --ninja-z-index: 50;
}
</style>
