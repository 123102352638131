import {useCssVar, useStorage} from '@vueuse/core'
import {computed, watch} from 'vue'
import {
  chartBaseColors,
  chartColorBlindColors,
} from '@/accessibility/data/chartColors.ts'

export default function useAccessibility() {
  const bodyElement = document.querySelector('body')

  const isColorBlindnessModeEnabled = useStorage(
    'offerfit/accessibility/colorBlindnessMode',
    false,
  )

  const accessibleChartColors = computed(() =>
    isColorBlindnessModeEnabled.value ? chartColorBlindColors : chartBaseColors,
  )

  const isReadableFontEnabled = useStorage(
    'offerfit/accessibility/readableFont',
    false,
  )
  const fontFamily = useCssVar('--offerfit-font-family', bodyElement)

  watch(
    isReadableFontEnabled,
    (value) => {
      fontFamily.value = value ? 'Inter' : 'Jost'
    },
    {immediate: true},
  )

  return {
    isColorBlindnessModeEnabled,
    accessibleChartColors,
    isReadableFontEnabled,
  }
}
