import {type MaybeRef} from 'vue'

// @unocss-include
const fieldTypeMap = [
  {
    type: 'all',
    label: 'All',
    icon: 'i-carbon-collapse-all',
    color: 'text-gray-500',
    boxColor: 'bg-gray-500/20',
  },
  {
    type: 'boolean',
    label: 'Boolean',
    icon: 'i-carbon-boolean',
    color: 'text-green-500',
    boxColor: 'bg-green-500/20',
  },
  {
    type: 'date',
    label: 'Date',
    icon: 'i-carbon-calendar',
    color: 'text-blue-500',
    boxColor: 'bg-blue-500/20',
  },
  {
    type: 'datetime',
    label: 'Datetime',
    icon: 'i-carbon-calendar-heat-map',
    color: 'text-blue-600',
    boxColor: 'bg-blue-500/20',
  },
  {
    type: 'integer',
    label: 'Integer',
    icon: 'i-carbon-character-whole-number',
    color: 'text-orange-500',
    boxColor: 'bg-orange-500/20',
  },
  {
    type: 'float',
    label: 'Float',
    icon: 'i-carbon-calculator',
    color: 'text-orange-600',
    boxColor: 'bg-orange-500/20',
  },
  {
    type: 'string',
    label: 'String',
    icon: 'i-carbon-string-text',
    color: 'text-purple-500',
    boxColor: 'bg-purple-500/20',
  },
  {
    type: 'value_list',
    label: 'Value list',
    icon: 'i-carbon-array-objects',
    color: 'text-teal-500',
    boxColor: 'bg-teal-500/20',
  },
] as const

export type FieldTypeValue = (typeof fieldTypeMap)[number]['type']

export type FieldType = {
  type: FieldTypeValue
  label: string
  icon: string
  color: string
  boxColor: string
}

export type FieldTypeOption = {
  label: string
  value: FieldTypeValue
}

type UseFieldTypesOptions = {
  omit?: MaybeRef<FieldTypeValue[]>
}
export const useFieldTypes = (options?: UseFieldTypesOptions) => {
  const omit = unref(options?.omit)
  const fieldTypes = fieldTypeMap

  const getFieldTypeByType = (type: FieldTypeValue) => {
    return fieldTypes.find((t) => t.type === type)
  }

  const fieldTypeOptions = computed<FieldTypeOption[]>(() => {
    return fieldTypes
      .filter((type) => !omit?.includes(type.type))
      .map((type) => ({
        label: type.label,
        value: type.type,
      }))
  })

  return {
    fieldTypes,
    fieldTypeOptions,
    getFieldTypeByType,
  }
}
