/* eslint-disable @typescript-eslint/naming-convention */
import type {GlobalThemeOverrides} from 'naive-ui'
import {mapValues} from 'es-toolkit'
import {colord} from 'colord'
import {getColorTheme} from '../generate.js'

const createThemeOverrides = (mode: 'light' | 'dark'): GlobalThemeOverrides => {
  const {light, dark} = getColorTheme('offerfit-v2')
  const baseTokens = mode === 'light' ? light : dark
  const tokens = mapValues(baseTokens, (value) => {
    const hslColor = colord(`hsl(${value})`)
    if (hslColor.isValid()) return hslColor.toHex()
    return value
  })

  return {
    common: {
      primaryColor: tokens.primary,
      primaryColorHover: colord(tokens.primary).alpha(0.8).toHex(),
      primaryColorPressed: colord(tokens.primary).alpha(0.6).toHex(),
      successColor: tokens.success,
      successColorHover: colord(tokens.success).alpha(0.8).toHex(),
      successColorPressed: colord(tokens.success).alpha(0.6).toHex(),
      infoColor: tokens.info,
      infoColorHover: colord(tokens.info).alpha(0.8).toHex(),
      infoColorPressed: colord(tokens.info).alpha(0.6).toHex(),
      warningColor: tokens.warning,
      warningColorHover: colord(tokens.warning).alpha(0.8).toHex(),
      warningColorPressed: colord(tokens.warning).alpha(0.6).toHex(),
      errorColor: tokens.destructive,
      errorColorHover: colord(tokens.destructive).alpha(0.8).toHex(),
      errorColorPressed: colord(tokens.destructive).alpha(0.6).toHex(),
      borderRadius: tokens.radius,
      cardColor: tokens.card,
      inputColor: tokens.input,
      baseColor: tokens.background,
      borderColor: tokens.border,
      hoverColor: tokens.accent,
      textColorBase: tokens.foreground,
      popoverColor: tokens.popover,
      scrollbarColor: tokens.muted,
      scrollbarColorHover: tokens['muted-foreground'],
      bodyColor: tokens.background,
      invertedColor: tokens.foreground,
      modalColor: tokens.background,
    },
    Select: {
      peers: {
        InternalSelectMenu: {
          optionTextColor: tokens.foreground,
          optionTextColorActive: tokens.primary,
          color: tokens.popover,
          optionColorActive: tokens.accent,
          optionCheckColor: tokens.success,
        },
        InternalSelection: {
          textColor: tokens.foreground,
          colorActive: colord(tokens.primary).alpha(0.07).toHex(),
        },
      },
    },
    Tooltip: {
      color: tokens.tooltip,
      textColor: tokens['tooltip-foreground'],
      boxShadow: tokens.ring,
    },
    Avatar: {
      color: tokens.muted,
    },
    Button: {
      // <NButton> Default
      color: tokens.background,
      colorHover: tokens.muted,
      colorPressed: tokens.background,
      textColor: tokens.foreground,
      colorFocus: tokens.accent,
      border: `1px solid ${tokens.border}`,
      borderHover: tokens.accent,
      borderPressed: tokens.foreground,
      borderFocus: tokens.muted,
      textColorHover: tokens['muted-foreground'],
      textColorPressed: tokens['muted-foreground'],
      textColorFocus: tokens.foreground,

      // <NButton type="primary"> Is taken from the common.primaryColor

      // <NButton type="primary" secondary> Is taken from the common.primaryColorHover
    },
    Card: {
      borderColor: tokens['card-border'],
      titleTextColor: tokens.foreground,
    },
    Divider: {
      color: tokens.border,
    },
    Form: {
      labelTextColor: tokens.foreground,
      feedbackFontSizeLarge: '0.9rem',
      feedbackFontSizeMedium: '0.8rem',
      feedbackFontSizeSmall: '0.7rem',
    },
    Tabs: {
      tabTextColor: tokens.foreground,
      tabTextColorBar: tokens.foreground,
      barColor: tokens.primary,
      tabTextColorLine: tokens['muted-foreground'],
      tabBorderColor: tokens.border,
      tabTextColorHoverLine: tokens.foreground,
      tabTextColorHoverBar: tokens.foreground,
    },
    DataTable: {
      thColor: tokens.accent,
      thTextColor: tokens['muted-foreground'],
      borderColor: tokens.border,
      tdColor: tokens.background,
      tdTextColor: tokens.foreground,
      tdColorHover: tokens.accent,
      fontSizeSmall: '0.8rem',
      fontSizeMedium: '0.9rem',
      fontSizeLarge: '1rem',
    },
    Switch: {
      railColor: tokens.muted,
      borderColor: tokens.foreground,
      railColorActive: tokens.success,
    },
    Tag: {
      color: tokens.muted,
      textColor: tokens['muted-foreground'],
      colorBordered: tokens.background,
      textColorBordered: tokens.foreground,
      borderColor: tokens.border,
      closeColorHover: colord(tokens.destructive).alpha(0.2).toHex(),
      closeIconColorHover: tokens.destructive,
    },
    Input: {
      color: tokens.background,
      textColor: tokens.foreground,
      borderColor: tokens.border,
      iconColor: tokens.muted,
      iconColorHover: tokens.muted,
      iconColorPressed: tokens.foreground,
    },
    Alert: {
      color: tokens.muted,
      titleTextColor: tokens.foreground,
      contentTextColor: tokens['muted-foreground'],
      iconColor: tokens.foreground,
      border: `1px solid ${tokens.border}`,
      colorError: colord(tokens.destructive).alpha(0.1).toHex(),
      colorInfo: colord(tokens.info).alpha(0.1).toHex(),
      colorSuccess: colord(tokens.success).alpha(0.1).toHex(),
      colorWarning: colord(tokens.warning).alpha(0.1).toHex(),
      borderError: `1px solid ${tokens.destructive}`,
      borderInfo: `1px solid ${tokens.info}`,
      borderSuccess: `1px solid ${tokens.success}`,
      borderWarning: `1px solid ${tokens.warning}`,
      iconColorError: tokens.destructive,
      iconColorInfo: tokens.info,
      iconColorSuccess: tokens.success,
      iconColorWarning: tokens.warning,
      titleTextColorInfo: tokens['info-foreground'],
      titleTextColorError: tokens['destructive-foreground'],
      titleTextColorWarning: tokens['warning-foreground'],
      titleTextColorSuccess: tokens['success-foreground'],
      contentTextColorInfo: tokens['info-foreground'],
      contentTextColorError: tokens['destructive-foreground'],
      contentTextColorWarning: tokens['warning-foreground'],
      contentTextColorSuccess: tokens['success-foreground'],
      closeColorHover: tokens.background,
      closeColorHoverWarning: tokens.background,
      closeColorHoverSuccess: tokens.background,
      closeColorHoverInfo: tokens.background,
      closeColorHoverError: tokens.background,
      closeBorderRadius: '3px',
      closeIconColorError: tokens.destructive,
      closeIconColorWarning: tokens.warning,
      closeIconColorSuccess: tokens.success,
      closeIconColorInfo: tokens.info,
      closeIconColor: tokens.foreground,
      closeIconColorHoverError: tokens['destructive-foreground'],
      closeIconColorHoverWarning: tokens['warning-foreground'],
      closeIconColorHoverSuccess: tokens['success-foreground'],
      closeIconColorHoverInfo: tokens['info-foreground'],
      closeIconColorPressedError: tokens['destructive-foreground'],
      closeIconColorPressedWarning: tokens['warning-foreground'],
      closeIconColorPressedSuccess: tokens['success-foreground'],
      closeIconColorPressedInfo: tokens['info-foreground'],
      closeColorPressed: colord(tokens.destructive).alpha(0.5).toHex(),
      closeColorPressedWarning: colord(tokens.warning).alpha(0.5).toHex(),
      closeColorPressedSuccess: colord(tokens.success).alpha(0.5).toHex(),
      closeColorPressedInfo: colord(tokens.info).alpha(0.5).toHex(),
      closeColorPressedError: colord(tokens.destructive).alpha(0.5).toHex(),
    },
  } satisfies GlobalThemeOverrides
}

export const naiveUiOfferfitThemeV2Light = createThemeOverrides('light')
export const naiveUiOfferfitThemeV2Dark = createThemeOverrides('dark')

export const naiveUiOfferfitThemeV2 = {
  light: naiveUiOfferfitThemeV2Light,
  dark: naiveUiOfferfitThemeV2Dark,
}
