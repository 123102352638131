<script setup lang="ts">
import CreateClientModal from '@level3/features/clients/components/CreateClientModal.vue'

const isModalVisible = ref(false)
</script>

<template>
  <CreateClientModal v-model:show="isModalVisible" />
  <div class="flex flex-col px-6 pt-10">
    <p class="text-2xl font-semibold">Manage Clients</p>
    <div class="mt-4 flex self-end gap-4">
      <NButton ternary @click="isModalVisible = true">
        Create new client
      </NButton>
    </div>
  </div>
</template>
