<script lang="ts" setup>
import TokensMinimapSfmcLaunch from '@/level4/views/modules/launch-sfmc/TokensMinimapSfmcLaunch.vue'
import CopyButton from '@/level4/components/common/CopyButton.vue'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'

const { experimenterFormState } = useExperimenterFormState()

const platformParams = computed<string>(() => {
  return experimenterFormState.value?.usecase?.config?.marketing_platform_connection?.platform_params
})

async function copyClickHandler(value: string) {
  await navigator.clipboard.writeText(value)
}

const columns = [
  { title: 'NAME', key: 'name', render(row) {
    return h('div', [
      h('span', row.name),
      h(CopyButton, {
        onClick: () => copyClickHandler(row.name),
      }),
    ])
  } },
  { title: 'EXTERNAL KEY', key: 'id', render(row) {
    return h('div', [
      h('span', row.id),
      h(CopyButton, {
        onClick: () => copyClickHandler(row.id),
      }),
    ])
  } },
]

defineExpose<FormExposedProperties>({
  nextFormButtonText: 'Continue',
  dataIsComplete: computed(() => !!platformParams.value?.automation),
})
</script>

<template>
  <WizardFormPageContainer
    containerSize="medium"
    supertitle="OfferFit successfully provisioned the below resources in your SFMC instance"
    title="Review the resources from your SFMC Automation Studio"
  >
    <TokensMinimapSfmcLaunch />
    <NCard
      :segmented="{ content: true }"
      class="mt-6"
    >
      <template #header>
        Queries automation
      </template>
      <template #header-extra>
        <NTag :type="platformParams?.automation ? 'success' : 'error'" round class="ml-4">
          Added
          <template #icon>
            <div :class="[platformParams?.automation ? 'i-solar-check-circle-bold text-sm' : 'i-solar-close-circle-bold text-sm']" />
          </template>
        </NTag>
      </template>
      <div v-if="!experimenterFormState" class="flex justify-center">
        <NSpin :show="!experimenterFormState" />
      </div>
      <div v-if="experimenterFormState && platformParams?.automation">
        <div class="pb-2">
          <p class="text-base font-medium">
            Automation name
          </p>
          <span>{{ platformParams?.automation }}</span>
          <CopyButton class="ml-2" @click="copyClickHandler(platformParams?.automation)" />
        </div>
        <div class="pb-2 mt-4">
          <p class="text-base font-medium mb-1">
            Subscribers query
          </p>
          <NDataTable :columns="columns" :data="[{ name: platformParams?.subscribers_query_activity_id, id: platformParams?.subscribers_query_activity }]" />
        </div>
        <div class="pb-4 mt-4">
          <p class="text-base font-medium mb-1">
            Engagement query
          </p>
          <NDataTable :columns="columns" :data="[{ name: platformParams?.engagement_query_activity_id, id: platformParams?.engagement_query_activity }]" />
        </div>
      </div>
      <div v-else-if="experimenterFormState && !platformParams?.automation">
        <p class="text-gray-500">
          Your experimenter has not been initialized. Try saving email templates or contact OfferFit support.
        </p>
      </div>
    </NCard>
  </WizardFormPageContainer>
</template>
