<script setup lang="ts">
import ConfigureKeys from './configure-keys/Form.vue'
import SendClassification from './send-classification/Form.vue'
import L4WizardContainer from '@/level4/views/L4WizardContainer.vue'
import { SFMCSteps } from '@/level4/data/forms'

const formSteps = [
  {
    name: SFMCSteps.PLATFORM_CONFIGURATION_SMFC_CONFIGURE_KEYS,
    component: ConfigureKeys,
  },
  {
    name: SFMCSteps.PLATFORM_CONFIGURATION_SMFC_SEND_CLASSIFICATION,
    component: SendClassification,
  },
]
</script>

<template>
  <div>
    <L4WizardContainer :steps="formSteps" />
  </div>
</template>
