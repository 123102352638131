import type {Client} from '@level3/features/clients/types/client'

export enum UserRole {
  AIE = 'aie', // TODO: rename to OfferFit User
  ADMIN = 'admin',
  EP = 'ep', // TODO: rename to Implementation Partner
  READER = 'reader', // TODO: rename to FOF Customer
  CUSTOMER = 'customer', // TODO: rename to OFSS Customer
  DEFAULT = 'authenticated',
}

export type User = {
  id: number
  username: string
  email: string
  provider?: string
  picture?: string
  confirmed: boolean
  blocked: boolean
  tos_accepted: boolean
  is_read_only: boolean
  is_offerfit_email_enabled: boolean
  role: {type?: UserRole; name?: string}
  allClients: Client[] | undefined // TODO: this seems to be extra from what is expected from the user request
  clients: Client[] | undefined
}
