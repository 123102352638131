<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse'
import { colors } from '@theme'
import { getCountableNounForm } from '@shared/utils/helpers'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import type { EmailCampaign, ImportedEmailTemplate } from '@/level4/composables/useExperimenterFormState'
import { INTEGRATION_PLATFORM, PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { LoadingStatus, useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const selectedCampaignTemplateIds = ref<string[]>([])
const selectedTemplates = ref<ImportedEmailTemplate[]>([])
const duplicatedTemplates = ref<ImportedEmailTemplate[]>([])
const loadedCampaigns = ref<EmailCampaign[]>([])
const currentLoadedCampaigns = ref<EmailCampaign[]>([])
const isLoadingCampaigns = ref<boolean>(true)
const isInfoOpen = ref<boolean>(true)
const pagination = reactive({ page: 1, pageSize: 10, total: -1 })
const searchTerm = ref<string>('')
const debouncedSearchTerm = refDebounced(searchTerm, 700)

const {
  experimenterFormState,
  setImportedCampaigns,
  addCurrentImportedTemplatesFromImportedCampaign,
  loadCampaigns,
  loadCampaignTemplates,
  checkWhetherImportedTemplatesAreAlreadyInUse,
  campaignTemplatesLoadingMap,
} = useExperimenterFormState()

const areAllTemplatesSelected = ref(false)

onMounted(async () => {
  loadedCampaigns.value = await loadCampaigns()
  pagination.total = loadedCampaigns.value.length
  isLoadingCampaigns.value = false
})

const { results: filteredLoadedCampaigns } = useFuse(debouncedSearchTerm, loadedCampaigns, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['name'],
  },
})

watch(filteredLoadedCampaigns, () => {
  pagination.total = filteredLoadedCampaigns.value.length
  pagination.page = 1
})

watch([() => pagination.page, filteredLoadedCampaigns], async () => {
  const start = (pagination.page - 1) * pagination.pageSize
  const end = start + pagination.pageSize
  const currentFilteredLoadedCampaigns = filteredLoadedCampaigns.value.map(({ item: campaign }) => campaign).slice(start, end)

  currentLoadedCampaigns.value = currentFilteredLoadedCampaigns

  campaignTemplatesLoadingMap.value = new Map<string, LoadingStatus>()
  const loadPromises = currentFilteredLoadedCampaigns.map(async (campaign) => {
    const templates = await loadCampaignTemplates(campaign)

    currentLoadedCampaigns.value = currentLoadedCampaigns.value.map((c) => {
      if (c.id === campaign.id) {
        return { ...c, email_templates: templates }
      }
      return c
    })
  })

  await Promise.allSettled(loadPromises)
}, { immediate: true })

const numberOfPages = computed(() => Math.ceil(pagination.total / pagination.pageSize))

function selectAllCampaignTemplates() {
  selectedCampaignTemplateIds.value = currentLoadedCampaigns.value
    .map(campaign => campaign.email_templates)
    .flat()
    .map(template => template?.id)
    .filter(id => id) as string[]
}

function unselectAllCampaignTemplates() {
  selectedCampaignTemplateIds.value = []
}

function toggleAllTemplatesSelection() {
  if (areAllTemplatesSelected.value) {
    selectAllCampaignTemplates()
  }
  else {
    unselectAllCampaignTemplates()
  }
}

const flattenedTemplates = computed(() => {
  return currentLoadedCampaigns.value.flatMap(campaign => campaign.email_templates ?? [])
})

const uniqueTemplates = computed(() => {
  return [...new Map(flattenedTemplates.value.map(item =>
    [item.id, item])).values()]
})

const shouldOpenAllCollapseItems = computed(() => {
  return searchTerm.value?.length > 0
})

const campaignList = computed(() =>
  currentLoadedCampaigns?.value?.map(campaign => ({
    id: campaign.id,
    name: campaign.name,
    email_templates: campaign.email_templates?.map(template => ({
      id: template.id,
      templateTitle: template.name,
      thumbnailUrl: template.thumbnail_url,
    })) ?? [],
  })),
)

const expandedNames = useLocalStorage('offerfit/choose-email-from-platform/expanded-names', [] as string[])
const computedExpandedNames = computed({
  get() {
    if (shouldOpenAllCollapseItems.value) {
      return campaignList.value.map(campaign => campaign.id)
    }
    return expandedNames.value
  },
  set(newExpanded: string[]) {
    if (shouldOpenAllCollapseItems.value) {
      return
    }
    expandedNames.value = newExpanded
  },
})

watch([uniqueTemplates, selectedCampaignTemplateIds], ([uniqueTemplates, selectedCampaignTemplateIds], [_, previousSelectedCampaignTemplateIds]) => {
  if (selectedCampaignTemplateIds.length === previousSelectedCampaignTemplateIds.length) {
    return
  }

  const addedTemplateIds = selectedCampaignTemplateIds.filter(id => !previousSelectedCampaignTemplateIds.includes(id))
  const removedTemplateIds = previousSelectedCampaignTemplateIds.filter(id => !selectedCampaignTemplateIds.includes(id))

  const addedTemplatesMap = uniqueTemplates.filter(template => addedTemplateIds.includes(template.id))
  selectedTemplates.value = [...selectedTemplates.value, ...addedTemplatesMap]

  selectedTemplates.value = selectedTemplates.value.filter(template => !removedTemplateIds.includes(template.id))
})

watch(selectedCampaignTemplateIds, () => {
  duplicatedTemplates.value = checkWhetherImportedTemplatesAreAlreadyInUse(selectedTemplates.value)!
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => {
    return !!loadedCampaigns.value.length && !!selectedTemplates.value.length
  }),
  submitHandler: async () => {
    await setImportedCampaigns(loadedCampaigns.value)
    await addCurrentImportedTemplatesFromImportedCampaign(selectedTemplates.value)
  },
})

const supertitle = computed(() => `Here are the email templates I see in your ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} account.`)
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium" :supertitle="supertitle"
    title="Which of these emails would you like to use?"
  >
    <BlueInfoMessage
      v-if="experimenterFormState?.platform === INTEGRATION_PLATFORM.KLAVIYO && isInfoOpen"
      closable
      link="https://app.tango.us/app/workflow/Setting-up-a-Klaviyo-placeholder-Flow-for-your-templates-b952801fbdf245a08621f12354d0ca64"
      title="OfferFit pulls templates associated with your existing Klaviyo Flows."
      subtitle=" "
      extendedSubtitle="If you don’t see your templates here, create a placeholder Flow containing the templates you need."
      class="mb-10"
      @dismiss="isInfoOpen = false"
    />
    <div class="top-actions-toolbar" w="full" flex="~" justify="between">
      <div class="start-block" flex="~" items="center" gap="4">
        <NCheckbox
          id="select-all" v-model:checked="areAllTemplatesSelected" min="w-25" :disabled="searchTerm?.length > 0"
          @update:checked="toggleAllTemplatesSelection"
        >
          Select All
        </NCheckbox>
        <NInput v-model:value="searchTerm" placeholder="Search" w="full" max="w-sm" clearable>
          <template #suffix>
            <div class="i-solar-magnifer-linear" />
          </template>
        </NInput>
        <NTag v-if="selectedCampaignTemplateIds?.length" :bordered="false" round cursor="pointer">
          {{ selectedCampaignTemplateIds?.length }} base
          {{ getCountableNounForm(selectedCampaignTemplateIds?.length, 'email', 'emails') }} selected
        </NTag>
      </div>
    </div>
    <div v-if="isLoadingCampaigns" class="loading-template-list-skeleton" flex="~ col" gap="8" p="y-4">
      <NSkeleton :repeat="4" height="40px" :sharp="false" w="full" />
    </div>
    <div v-else class="email-templates-list" w="full" p="y-4">
      <NCard v-if="!loadedCampaigns.length" embedded>
        <NEmpty w="full">
          <template #default>
            <h1 font="bold">
              No Campaigns found. Please go to your platform and create a campaign. <br>If campaigns are
              created, please return to Step 1 - Platform Connection - and verify that all settings are correct.
            </h1>
          </template>
        </NEmpty>
      </NCard>
      <NEmpty v-else-if="!filteredLoadedCampaigns.length" w="full">
        <template #default>
          <h1 font="bold">
            No results for "{{ searchTerm }}"
          </h1>
        </template>
      </NEmpty>
      <NPagination v-model:page="pagination.page" class="mb-6" :pageCount="numberOfPages" />
      <NCollapse v-if="campaignList.length" v-model:expanded-names="computedExpandedNames">
        <NCollapseItem v-for="campaign in campaignList" :key="campaign.id" :name="campaign.id" w="full">
          <template #header-extra>
            <NTag
              v-if="campaignTemplatesLoadingMap.get(campaign.id) === LoadingStatus.LOADED" :bordered="false" round
              cursor="pointer" size="small" m="r-2"
            >
              {{ campaign.email_templates?.length }}
              {{ getCountableNounForm(campaign.email_templates?.length, 'Template', 'Templates') }}
            </NTag>
            <div v-if="campaignTemplatesLoadingMap.get(campaign.id) === LoadingStatus.LOADING" class="template-count">
              <NSpin
                class="mr-1 h-5 w-5 filter-grayscale !relative !left-0 !top-0 !transform-none" :stroke-width="30"
                :size="16"
              />
            </div>
            <div v-if="campaignTemplatesLoadingMap.get(campaign.id) === LoadingStatus.FAILED" class="template-count">
              <NTag type="warning" :color="{ textColor: colors.brand.orange }" round :bordered="false">
                Failed
                <template #icon>
                  <div class="i-solar-danger-triangle-bold" m="l-1" text="sm" />
                </template>
              </NTag>
            </div>
          </template>
          <template #header>
            <h1 font="bold">
              {{ campaign.name }}
            </h1>
          </template>
          <NCard embedded>
            <NEmpty
              v-if="!campaignTemplatesLoadingMap.get(campaign.id) && !campaign.email_templates?.length"
              description="No email templates found for this campaign."
            />
            <EmailTemplatesSelectGrid
              v-else v-model:checked-email-templates-ids="selectedCampaignTemplateIds"
              :emailTemplates="campaign.email_templates"
              :loading="campaignTemplatesLoadingMap.get(campaign.id)"
              w="full"
            />
          </NCard>
        </NCollapseItem>
      </NCollapse>
    </div>
    <div v-if="duplicatedTemplates.length" class="alert-duplication" w="full" p="y-4">
      <NAlert
        type="warning" closable showIcon
        :title="`The following templates are already in use: ${duplicatedTemplates.map(template => template.name).join(', ')}`"
      >
        Adding this template will create a new base email
      </NAlert>
    </div>
  </WizardFormPageLayout>
</template>
