<script setup lang="ts">
import { maskPassword } from 'maskdata'
import { validateURLString } from '@shared/utils/helpers.ts'
import type { SFMCIntegrationData } from '@/level4/services/PlatformIntegrationService.ts'
import { useIntegrationsSync } from '@/level4/composables/useIntegrationsSync'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { experimenterFormState, updateExperimenterFormState } = useExperimenterFormState()
const { createDataInVault } = useIntegrationsSync()

const currentClientSecret = ref('')
const existedClientSecret = ref('')
const existingPublicCredentials = ref<Partial<SFMCIntegrationData>>({})
syncRefs(() => toRaw(experimenterFormState.value?.sfmcClientSecret), existedClientSecret, { immediate: true })
syncRefs(() => toRaw({
  sfmcClientId: experimenterFormState.value?.sfmcClientId,
  sfmcAuthBaseUri: experimenterFormState.value?.sfmcAuthBaseUri,
  sfmcRestBaseUri: experimenterFormState.value?.sfmcRestBaseUri,
  sfmcSoapBaseUri: experimenterFormState.value?.sfmcSoapBaseUri,
}), existingPublicCredentials, { immediate: true })

const integrationFields = ref<Partial<SFMCIntegrationData>>({
  sfmcClientId: '',
  sfmcAuthBaseUri: '',
  sfmcRestBaseUri: '',
  sfmcSoapBaseUri: '',
})

const computedMaskedClientSecret = computed(() => {
  return maskPassword(existedClientSecret.value, { maskWith: '*', unmaskedStartCharacters: 4 })
})

const hasDataChanged = computed(() => {
  return (!!currentClientSecret.value && existedClientSecret.value !== currentClientSecret.value)
    || (!!integrationFields.value.sfmcClientId && integrationFields.value.sfmcClientId !== experimenterFormState.value?.sfmcClientId)
    || (!!integrationFields.value.sfmcAuthBaseUri && integrationFields.value.sfmcAuthBaseUri !== experimenterFormState.value?.sfmcAuthBaseUri)
    || (!!integrationFields.value.sfmcRestBaseUri && integrationFields.value.sfmcRestBaseUri !== experimenterFormState.value?.sfmcRestBaseUri)
    || (!!integrationFields.value.sfmcSoapBaseUri && integrationFields.value.sfmcSoapBaseUri !== experimenterFormState.value?.sfmcSoapBaseUri)
})

const isDataComplete = computed(() => {
  return !!integrationFields.value?.sfmcClientId
    && !!currentClientSecret.value
    && !!integrationFields.value.sfmcAuthBaseUri
    && !!integrationFields.value.sfmcRestBaseUri
    && !!integrationFields.value.sfmcSoapBaseUri
})

const isDataCompleteAndValid = computed(() => {
  if (isDataComplete.value) {
    return validateURLString(integrationFields.value.sfmcAuthBaseUri) && validateURLString(integrationFields.value.sfmcRestBaseUri) && validateURLString(integrationFields.value.sfmcSoapBaseUri)
  }
  return false
})

const isStateDataNotEmpty = computed(() => {
  return !!experimenterFormState.value?.sfmcClientSecret
    && !!experimenterFormState.value?.sfmcClientId
    && !!experimenterFormState.value?.sfmcAuthBaseUri
    && !!experimenterFormState.value?.sfmcRestBaseUri
    && !!experimenterFormState.value?.sfmcSoapBaseUri
})

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => isDataCompleteAndValid.value || isStateDataNotEmpty.value),
  skipIsAvailable: computed(() => isStateDataNotEmpty.value && !hasDataChanged.value),
  submitHandler: async () => {
    // If nothing is changed from copied credentials, the following code will be skipped.
    // If one or more parts are updated from copied credentials or the credential is new:
    const fields = {
      sfmcClientId: integrationFields.value.sfmcClientId || experimenterFormState.value?.sfmcClientId,
      sfmcAuthBaseUri: integrationFields.value.sfmcAuthBaseUri || experimenterFormState.value?.sfmcAuthBaseUri,
      sfmcRestBaseUri: integrationFields.value.sfmcRestBaseUri || experimenterFormState.value?.sfmcRestBaseUri,
      sfmcSoapBaseUri: integrationFields.value.sfmcSoapBaseUri || experimenterFormState.value?.sfmcSoapBaseUri,
    }

    await updateExperimenterFormState({ sfmcClientSecret: currentClientSecret.value || existedClientSecret.value, ...fields })
    await createDataInVault()
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="small" footerSize="full"
    supertitle="First, we'll need to set up an integration with SFMC"
    title="Create an SFMC API package for OfferFit to use and provide its details below"
  >
    <template #thirdSection>
      <BasicLink
        href="https://app.tango.us/app/workflow/Setting-up-an-SFMC-App-Package-for-OfferFit-7aab26a6e9dd46d79bbebdb413c6c0fd"
        target="_blank"
        class="cursor-pointer text-link"
        withExternalIcon
      >
        How to set up an API package and permissions required?
      </BasicLink>
    </template>
    <form flex="~ col" gap="4" items="center" w="full">
      <BasicInput id="client_id_input" v-model="integrationFields.sfmcClientId" label="Client ID" :placeholder="existingPublicCredentials?.sfmcClientId || 'Paste client ID'" data-sentry-mask />
      <BasicInput
        id="client_secret_input" v-model="currentClientSecret" label="Client Secret"
        :placeholder="computedMaskedClientSecret || 'Paste client secret'"
        data-sentry-mask
      />
      <BasicInput
        id="auth_uri_input" v-model="integrationFields.sfmcAuthBaseUri" label="Authentication Base URI"
        :placeholder="existingPublicCredentials?.sfmcAuthBaseUri || 'Paste authentication base URI'" data-sentry-mask
      />
      <p v-if="integrationFields.sfmcAuthBaseUri && !validateURLString(integrationFields.sfmcAuthBaseUri)" class="text-xs text-red-600">
        This URL address must be valid.
      </p>
      <BasicInput
        id="rest_base_input" v-model="integrationFields.sfmcRestBaseUri" label="REST Base URI"
        :placeholder="existingPublicCredentials?.sfmcRestBaseUri || 'Paste REST base URI'" data-sentry-mask
      />
      <p v-if="integrationFields.sfmcRestBaseUri && !validateURLString(integrationFields.sfmcRestBaseUri)" class="text-xs text-red-600">
        This URL address must be valid.
      </p>
      <BasicInput
        id="soap_base_input" v-model="integrationFields.sfmcSoapBaseUri" label="SOAP Base URI"
        :placeholder="existingPublicCredentials?.sfmcSoapBaseUri || 'Paste SOAP base URI'" data-sentry-mask
      />
      <p v-if="integrationFields.sfmcSoapBaseUri && !validateURLString(integrationFields.sfmcSoapBaseUri)" class="text-xs text-red-600">
        This URL address must be valid.
      </p>
    </form>
  </WizardFormPageLayout>
</template>

<style scoped></style>
