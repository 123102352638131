import {primitiveColors} from './primitive.js'

/**
 * The background variable is used for the background color of the component and the foreground variable is used for the text color.
 * The background suffix is omitted when the variable is used for the background color of the component.
 * Example:
 * <div className="bg-background text-primary-foreground">...</div>
 */
export const themeCssVariableKeys = [
  /**
   * Global base background and foreground, usually white and darkest grey
   * Default background color of <body />...etc
   */
  'background',
  'foreground',
  /**
   * Primary color and its foreground text color
   * Usage: Links, buttons, boxed icons, etc.
   */
  'primary',
  'primary-foreground',
  /**
   * Muted color and its foreground text color
   * Usage: Secondary text, disabled text, information text, dividers, etc.
   */
  'muted',
  'muted-foreground',
  /**
   * Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover />
   */
  'popover',
  'popover-foreground',
  /**
   * Accent color and its foreground text color
   * Usage: Hover states on dropdown options, calendar days hover states, etc.
   * Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc
   */
  'accent',
  'accent-foreground',
  /**
   * Status colors and their foreground text colors
   * Usage:
   * - Destructive: Destructive actions, error messages
   * - Warning: Warning messages, alerts
   * - Success: Success messages, alerts
   * - Info: Information messages, alerts
   */
  'destructive',
  'destructive-foreground',
  'warning',
  'warning-foreground',
  'success',
  'success-foreground',
  'info',
  'info-foreground',
  /**
   * Universal radius base
   */
  'radius',
  'border',
  'input',
  'card',
  'card-foreground',
  'card-border',
  'ring',
  'tooltip',
  'tooltip-foreground',
] as const

export type ThemeCssVariableKey = (typeof themeCssVariableKeys)[number]
export type ThemeCssVariables = {
  [K in ThemeCssVariableKey]: string
}
export type ThemeCssVariablesVariant = {
  name: string
  light: ThemeCssVariables
  dark: ThemeCssVariables
}

export const themes = [
  {
    name: 'offerfit-v2',
    label: 'Offerfit V2 Neutral',
    cssVars: {
      light: {
        background: primitiveColors.white,
        foreground: primitiveColors.neutral[900],
        negative: primitiveColors.neutral[900],
        'negative-foreground': primitiveColors.neutral[100],
        primary: primitiveColors.purple[500],
        'primary-foreground': primitiveColors.purple[50],
        popover: primitiveColors.light[50],
        'popover-accent': primitiveColors.light[400],
        'popover-foreground': primitiveColors.neutral[900],
        card: primitiveColors.white,
        'card-foreground': primitiveColors.neutral[900],
        'card-border': primitiveColors.neutral[200],
        muted: primitiveColors.neutral[200],
        'muted-foreground': primitiveColors.neutral[600],
        border: primitiveColors.neutral[300],
        input: primitiveColors.white,
        info: primitiveColors.blue[500],
        'info-foreground': primitiveColors.blue[900],
        warning: primitiveColors.yellow[500],
        'warning-foreground': primitiveColors.yellow[900],
        success: primitiveColors.green[500],
        'success-foreground': primitiveColors.green[900],
        destructive: primitiveColors.red[500],
        'destructive-foreground': primitiveColors.red[900],
        accent: primitiveColors.light[400],
        'accent-foreground': primitiveColors.neutral[900],
        radius: '0.5rem',
        ring: `0 0 1px 3px hsl(${primitiveColors.light[900]}/0.6)`,
        tooltip: primitiveColors.dark[950],
        'tooltip-foreground': primitiveColors.neutral[100],
      },
      dark: {
        background: primitiveColors.dark[900],
        foreground: primitiveColors.neutral[100],
        primary: primitiveColors.purple[300],
        'primary-foreground': primitiveColors.purple[300],
        card: primitiveColors.neutral[900],
        'card-foreground': primitiveColors.neutral[100],
        'card-border': primitiveColors.dark[400],
        popover: primitiveColors.dark[950],
        'popover-foreground': primitiveColors.neutral[100],
        'popover-accent': primitiveColors.dark[950],
        muted: primitiveColors.neutral[800],
        'muted-foreground': primitiveColors.neutral[400],
        border: primitiveColors.dark[300],
        input: primitiveColors.neutral[900],
        info: primitiveColors.blue[500],
        'info-foreground': primitiveColors.blue[200],
        warning: primitiveColors.yellow[500],
        'warning-foreground': primitiveColors.yellow[200],
        success: primitiveColors.green[500],
        'success-foreground': primitiveColors.green[200],
        destructive: primitiveColors.red[500],
        'destructive-foreground': primitiveColors.red[200],
        accent: primitiveColors.dark[700],
        'accent-foreground': primitiveColors.neutral[100],
        radius: '0.5rem',
        ring: `0 0 2px 1px hsl(${primitiveColors.dark[300]})`,
        tooltip: primitiveColors.dark[950],
        'tooltip-foreground': primitiveColors.neutral[100],
      },
    },
  },
]

export type Theme = (typeof themes)[number]
