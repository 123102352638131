<script setup lang="ts">
import { LaunchSFMCSteps } from '@/level4/data/forms'

const props = defineProps<{
  stepName: string
}>()

interface TokensMiniMapStep {
  label?: string
  icon?: VNode
  completed: boolean
  tooltip?: string
  group: LaunchSFMCSteps[]
}

// TODO: add complete calculation and related tooltip info

const formGroups = computed(() => {
  return [
    {
      label: 'Review resources',
      completed: false,
      group: [LaunchSFMCSteps.SFMC_REVIEW_RESOURCES],
    },
    {
      label: 'Activate journey',
      completed: false,
      group: [LaunchSFMCSteps.SFMC_FINISH_JOURNEY],
    },
    {
      label: 'Validate results',
      completed: false,
      group: [LaunchSFMCSteps.SFMC_VALIDATE_RESULTS],
    },
  ] satisfies TokensMiniMapStep[]
})
</script>

<template>
  <div class="wizard-forms-group-mini-map" flex="~" justify="center" m="4">
    <div class="wizard-form-group-steps" flex="~ wrap" gap="y-3">
      <div
        v-for="(formGroup, formGroupIndex) in formGroups"
        :key="formGroupIndex"
        flex="~"
        items="center"
        class="step-group"
      >
        <div
          :data-completed="formGroup.completed"
          :data-active="
            !!formGroup.group.includes(props.stepName)
          "
          class="wizard-form-group-step"
        >
          <div class="icon">
            <div
              v-if="formGroup.completed"
              text="brand-green"
              class="i-solar-check-circle-bold"
            />
            <component :is="formGroup.icon" v-else />
          </div>
          <div v-if="formGroup.label" class="label">
            {{ formGroupIndex + 1 }}. {{ formGroup.label }}
          </div>
        </div>
        <div
          v-if="formGroupIndex < formGroups.length - 1"
          text="sm gray-400"
          class="i-solar-alt-arrow-right-outline"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.wizard-form-group-step {
  @apply flex gap-1 cursor-default border-1 p-1 px-3 rounded-full;

  /*&:hover {*/
  /*  @apply bg-gray-100 opacity-100;*/
  /*}*/

  &[data-active="false"][data-completed="false"] {
    @apply opacity-75;
    & .icon {
      @apply text-brand-purple-light;
    }
  }

  &[data-active="true"] {
    @apply font-bold text-brand-purple-bright border-brand-purple-bright bg-brand-purple-bright/10;
    & .icon {
      @apply text-brand-purple-bright;
    }
  }
}
</style>
