import hat from 'hat'
import http from '@app/utils/http-client'
import type { Environment } from '@shared/data/constants'
import { PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import type { EmailCampaign } from '@/level4/composables/useExperimenterFormState'
import type { ScheduleActivationEventsModel } from '@/usecases/models/UseCaseConfigModel'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'

export interface EventHubCampaignTemplatesResponse {
  result: EmailCampaign | null
  error_code: string
  error_msg: string
}

export interface EventHubCampaignsResponse {
  result: {
    campaigns: { id: string, name: string, description: string, platform_params: unknown }[]
  }
  error_code: string
  error_msg: string
}

export interface EventHubImagesResponse {
  result: {
    images: string[]
  }
  error_code: string
  error_msg: string
}

export interface EventsHubAudiencesResponse {
  result: {
    audiences: { id: string, name: string, size: number }[]
  }
  error_code: string
  error_msg: string
}

export interface EventsHubAudienceValidationResponse {
  result: {
    id: string
    name: string
    size: number
  }
  error_code: string
  error_msg: string
}

export enum AUDIENCE_ERROR_CODE {
  NO_ROWS = 'SFMC-AUDIENCES-1009',
  NO_SUBSCRIBER_KEY = 'SFMC-AUDIENCES-1008',
}

export const SHORT_ERROR_MSG: Record<string, string> = {
  [AUDIENCE_ERROR_CODE.NO_ROWS]: 'No records found',
  [AUDIENCE_ERROR_CODE.NO_SUBSCRIBER_KEY]: 'SubscriberKey not found',
}

export default class OFEService {
  static async getCampaigns(experimenter: string, client: string) {
    return (await http.get<EventHubCampaignsResponse>(`/ofe/campaigns?experimenter=${experimenter}&client=${client}`)).data
  }

  static async getCampaignDetails(campaignId: string, experimenter: string, client: string, platformParams: unknown = null) {
    const url = `/ofe/campaigns/${campaignId}`
    const response = await http.post<EventHubCampaignTemplatesResponse>(url, { platform_params: platformParams, experimenter, client }, { cache:
        {
          methods: ['post'],
        },
    })
    return response.data
  }

  static async getAudiences(experimenter: string, client: string, useCache: boolean = true) {
    if (useCache) {
      return (await http.get<EventsHubAudiencesResponse>(`/ofe/audiences?experimenter=${experimenter}&client=${client}`)).data
    }

    return (await http.get<EventsHubAudiencesResponse>(`/ofe/audiences?experimenter=${experimenter}&client=${client}`, { cache: false })).data
  }

  static async validateAudience(audienceData: any, experimenter: string, client: string) {
    return (await http.post<EventsHubAudienceValidationResponse>(`/ofe/audiences/validate?experimenter=${experimenter}`, { audience: audienceData, client })).data
  }

  static async getSFMCSendClassications(client: string, experimenter: string): Promise<any> {
    return (await http.get(`/ofe/sfmc/send-classifications?experimenter=${experimenter}&client=${client}`)).data
  }

  static async createOrUpdatePlatformTemplates(
    experimenter: string,
    client: string,
    templateData: { templates: { name: string, html: string, cta?: string, heroImg?: string }[] },
  ): Promise<any> {
    return (await http.post(`/ofe/templates?experimenter=${experimenter}`, { ...templateData, client })).data
  }

  static async updatePlatformTemplate(
    templateId: string,
    experimenter: string,
    client: string,
    templateData: { name: string, html: string, cta?: string, heroImg?: string },
  ): Promise<any> {
    return (await http.put(`/ofe/templates/${templateId}?experimenter=${experimenter}`, { ...templateData, client })).data
  }

  static async getPlatformTemplate(
    templateId: string,
    experimenter: string,
    client: string,
  ): Promise<any> {
    return (await http.get(`/ofe/templates/${templateId}?experimenter=${experimenter}&client=${client}`)).data
  }

  static async getPlatformGalleryImages(experimenter: string, client: string): Promise<EventHubImagesResponse> {
    return (await http.get(`/ofe/import-images?experimenter=${experimenter}&client=${client}`)).data
  }

  // currently only used for klaviyo
  static async experimenterFinishSetUpHook(
    experimenter: string,
    client: string,
    pipelineName: string,
    flow_id: string,
  ): Promise<any> {
    return (await http.post(`/ofe/hooks/experimenter/finish?experimenter=${experimenter}&pipelineName=${pipelineName}`, { flow_id, client })).data
  }

  static async runScheduleHubEvents(
    experimenter: string,
    client: string,
    platform: INTEGRATION_PLATFORM,
    receivedTs: string,
    scheduledActivationEvents: ScheduleActivationEventsModel,
  ): Promise<any> {
    const config = scheduledActivationEvents.config
    const updatedConfig = { ...config, activation_timezone: 'UTC', api_batch_size: 1, recs_table_suffix: 'final_recs_validation' }
    const payload = {
      customer: client,
      use_case: experimenter,
      run_id: hat(),
      offerfit_received_ts: receivedTs,
      event_type: 'activation',
      target_platform: platform,
      config: updatedConfig,
    }

    if (platform === 'braze') {
      delete payload.config.sfmc
    }
    else {
      delete payload.config.braze
    }

    return http.post('/ofe/run_schedule', payload)
  }

  static async createSampleRecommendations(client: string, pipeline: string, experimenter: string, platform: string, mode: Environment, emails: string[], emailActionIds: number[]): Promise<any> {
    return http.post(`${PYTHON_MS_API_PREFIX}/ofe/final_recs_validation`, {
      client,
      pipeline,
      usecase: experimenter,
      marketing_platform: platform,
      product_mode: mode,
      test_emails: emails,
      email_action_ids: emailActionIds,
    })
  }
}
