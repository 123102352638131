import Global from '@shared/utils/global.ts'
import type { RouteLocationRaw } from 'vue-router'

export interface WizardStep {
  name: string
  component: Component
}

const wizardDirection = ref<'forward' | 'back'>('forward')

/**
 * Create a new wizard. Do not use this composable directly unless you are creating a new wizard.
 * @param  {WizardStep[] | (() => Promise<WizardStep[]>)} steps - The steps of the wizard.
 * @param  {FormExposedProperties} currentStepRef - The current step reference.
 * @param  {RouteLocationRaw | (() => Promise<RouteLocationRaw>)} redirectAfterLastStep - The redirect after the last step.
 * @param  {RouteLocationRaw | (() => Promise<RouteLocationRaw>)} redirectOnQuit - The redirect on quit.
 */
export function useWizard(
  steps: WizardStep[] | (() => Promise<WizardStep[]>),
  currentStepRef: any,
  redirectAfterLastStep?: RouteLocationRaw | (() => Promise<RouteLocationRaw>),
  redirectOnQuit?: RouteLocationRaw | (() => Promise<RouteLocationRaw>),
) {
  const route = useRoute()
  const router = useRouter()

  const resolvedSteps = ref<WizardStep[]>([])

  // resolve steps before component is mounted
  onBeforeMount(async () => {
    if (typeof steps === 'function') {
      const result = await steps()
      resolvedSteps.value = result
    }
    else {
      resolvedSteps.value = steps
    }
  })

  function getStepIndex(stepName?: string) {
    return stepName ? resolvedSteps.value.findIndex(s => s.name === stepName) : 0
  }

  const currentStepIndex = computed(() =>
    router.currentRoute && route.query?.step ? getStepIndex(route.query.step as string) : 0,
  )

  const currentStep = computed(() => {
    const step = route.query?.step
    return step ? resolvedSteps.value.find(s => s.name === step) : resolvedSteps.value[currentStepIndex.value]
  })

  const isFirstStep = computed(() => currentStepIndex.value === 0)
  const isLastStep = computed(() => currentStepIndex.value === resolvedSteps.value.length - 1)
  const stepIsCompleted = computed(() => {
    // we need to keep currentStep.value as trigger for completed value recalculation
    return currentStep.value && (currentStepRef.value?.dataIsComplete === undefined || currentStepRef.value?.dataIsComplete === true)
  })

  const stepSkippable = computed(() => {
    // we need to keep currentStep.value as trigger for completed value recalculation
    return currentStep.value && (currentStepRef.value?.skipIsAvailable !== undefined && currentStepRef.value?.skipIsAvailable === true)
  })

  async function redirectToParent() {
    const parentPath = route.path.split('/').slice(0, -1).join('/')
    await router.push({ path: parentPath })
  }

  async function redirectToTargetOrParent(target?: RouteLocationRaw | (() => Promise<RouteLocationRaw>)) {
    if (target) {
      const targetRoute = typeof target === 'function' ? await target() : target
      if (targetRoute) {
        await router.push(targetRoute)
      }
      else {
        await redirectToParent()
      }
    }
    else {
      await redirectToParent()
    }
  }

  async function setCurrentStepIndex(newIndex: number) {
    await router.push({
      name: route.name as string,
      query: { step: resolvedSteps.value[newIndex].name },
    })
  }

  async function next() {
    wizardDirection.value = 'forward'
    try {
      await currentStepRef.value?.submitHandler?.()
      if (isLastStep.value) {
        await redirectToTargetOrParent(redirectAfterLastStep)
      }
      else {
        await setCurrentStepIndex(currentStepIndex.value + 1)
      }
    }
    catch (error: any) {
      Global.error(error.message)
    }
  }

  async function skip() {
    wizardDirection.value = 'forward'
    if (isLastStep.value) {
      await redirectToTargetOrParent(redirectAfterLastStep)
    }
    else {
      await setCurrentStepIndex(currentStepIndex.value + 1)
    }
  }

  async function prev() {
    wizardDirection.value = 'back'
    if (isFirstStep.value) {
      await quit()
    }
    else {
      await setCurrentStepIndex(currentStepIndex.value - 1)
    }
  }

  async function quit() {
    await redirectToTargetOrParent(redirectOnQuit)
    await nextTick(() => {
      wizardDirection.value = 'forward'
    })
  }

  return {
    currentStep,
    currentStepIndex,
    setCurrentStepIndex,
    steps,
    next,
    skip,
    prev,
    quit,
    isFirstStep,
    isLastStep,
    stepIsCompleted,
    stepSkippable,
    wizardDirection,
  }
}
